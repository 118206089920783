import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from './authcontext';
import api from './api';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck } from '@fortawesome/free-solid-svg-icons';

const ManageOrganisations = () => {
    const { organisations, setOrganisations, setCurrentOrg, currentOrg } = useAuth(); // Updated to only use necessary variables
    const [newOrgName, setNewOrgName] = useState('');
    const [message, setMessage] = useState('');
    const [editableOrgId, setEditableOrgId] = useState(null);
    const [editingField, setEditingField] = useState('');
    const [editedOrgName, setEditedOrgName] = useState('');
    const inputRef = useRef(null);
    

    useEffect(() => {
        console.log('Organisations updated:', organisations);
    }, [organisations]);

    useEffect(() => {
        console.log('CurrentOrg updated:', currentOrg);
    }, [currentOrg]);

    // Automatically set the first organisation as currentOrg if none is set
    useEffect(() => {
        if (organisations?.length > 0 && !currentOrg) {
            setCurrentOrg(organisations[0]);
        }
    }, [organisations, currentOrg, setCurrentOrg]);

    const handleAddOrganisation = async () => {
        // Check if all required fields are filled
        if (
            !newOrgName.trim()
        ) {
            setMessage('Please fill in all fields before adding an organisation.');
            return;
        }

        // Check if the organization name already exists
        const existingOrg = organisations.find(
            org => org.name.toLowerCase() === newOrgName.toLowerCase()
        );
        if (existingOrg) {
            setMessage('An organisation with this name already exists. Please choose a different name.');
            return;
        }

        try {
            const response = await api.post('/addorganisation', {
                name: newOrgName,
            });
            setMessage(response.data.message);
    
            // Assuming the backend returns the new organisation
            const newOrganisation = response.data.organisation;
    
            // Set currentOrg to the new organisation
            setCurrentOrg(newOrganisation);
    
            // Clear the new organization name and defaults input
            setNewOrgName('');
    
            // Reload the page to refresh the context (optional)
            window.location.reload();

        } catch (error) {
            console.error('Error adding organisation:', error);
            setMessage('Failed to add organisation.');
        }
    };

    const handleSelectOrg = (event) => {
        const selectedOrgId = event.target.value;
        const selectedOrg = organisations.find(org => org.id === parseInt(selectedOrgId));
        setCurrentOrg(selectedOrg);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                if (editableOrgId !== null) {
                    handleSaveEditedOrgField(editableOrgId, editingField);
                }
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [editableOrgId, editingField, editedOrgName]);    

    if (!organisations) {
        return <p>Loading...</p>;
    }

    const handleEditOrgField = (orgId, field, currentValue) => {
        setEditableOrgId(orgId);
        setEditingField(field);
        if (field === 'name') {
            setEditedOrgName(currentValue);
        }
    };    

    const handleSaveEditedOrgField = async (orgId, field) => {
        let updatedData = {};
    
        if (field === 'name') {
            updatedData.name = editedOrgName;
        }
    
        try {
            await api.put(`/organisations/${orgId}`, updatedData);
    
            // Update the organisations state
            setOrganisations(prevOrgs =>
                prevOrgs.map(org =>
                    org.id === orgId ? { ...org, ...updatedData } : org
                )
            );
    
            // Update currentOrg if necessary
            if (currentOrg?.id === orgId) {
                setCurrentOrg(prevOrg => ({ ...prevOrg, ...updatedData }));
            }
    
            window.location.reload();
        } catch (error) {
            console.error('Error updating organisation:', error);
        }
    
        // Reset editing states
        setEditableOrgId(null);
        setEditingField('');
        setEditedOrgName('');
    };       
    

    return (
        <div>
            <div>
                {organisations.length > 0 && (
                    <div>
                        <h2>Select an Organisation</h2>
                        <select onChange={handleSelectOrg} value={currentOrg?.id || ''}>
                            <option value="" disabled>Select your organisation</option>
                            {organisations.map(org => (
                                <option key={org.id} value={org.id}>{org.name}</option>
                            ))}
                        </select>
                    </div>
                )}
                <h2>Add a New Organisation</h2>
                <input
                    type="text"
                    value={newOrgName}
                    onChange={(e) => setNewOrgName(e.target.value)}
                    placeholder="Enter organisation name"
                />
                <button onClick={handleAddOrganisation}>Add Organisation</button>
                {message && <p>{message}</p>}
            </div>
            {organisations.length > 0 && (
                <div>
                    <h2>Your Organisations</h2>
                    <div className='scrollable-table-container'>
                        <table className='data-table'>
                            <thead>
                                <tr>
                                    <th>Organisation Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {organisations.map(org => (
                                    <tr key={org.id}>
                                        {/* Organisation Name */}
                                        <td>
                                            {editableOrgId === org.id && editingField === 'name' ? (
                                                <div>
                                                    <input
                                                        ref={inputRef}
                                                        type="text"
                                                        value={editedOrgName}
                                                        onChange={(e) => setEditedOrgName(e.target.value)}
                                                    />
                                                    <FontAwesomeIcon 
                                                        icon={faCheck}
                                                        onClick={() => handleSaveEditedOrgField(org.id, 'name')} 
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                <Link to={`/organisation/${org.id}`}>
                                                    {org.name}
                                                </Link>                                                    
                                                <FontAwesomeIcon 
                                                        icon={faPen}
                                                        onClick={() => handleEditOrgField(org.id, 'name', org.name)} 
                                                    />
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageOrganisations;
