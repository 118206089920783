import React from 'react';
import { useAuth } from './authcontext'; // Adjust the import path if necessary

function MyAccount() {
    const { user } = useAuth();

    if (!user || !user.firstName || !user.lastName || !user.email) {
        return <div>No user data available.</div>;
    }

    return (
        <div>
            <h1>My Account</h1>
            <div>
                <div>
                    <strong>First Name:</strong> {user.firstName}
                </div>
                <div>
                    <strong>Last Name:</strong> {user.lastName}
                </div>
                <div>
                    <strong>Email:</strong> {user.email}
                </div>
            </div>
        </div>
    );
}

export default MyAccount;
