// XeroLayoutEditor.js
import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../authcontext';
import api from '../api';

import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

// Generate unique ID if needed.
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * SortableRow
 *
 * A draggable row for account/subheading/etc.
 * It now supports:
 * - Editable labels: double-click to enter edit mode.
 * - For subheadings: shows a remove (bin) icon if the row is nested (i.e. not top-level),
 *   with a more subtle grey color.
 */
function SortableRow({
  row,
  parentPath,
  collapsedBlocks,
  toggleCollapse,
  selectedRows,
  onCheckboxChange,
  onLabelUpdate,
  onRemoveGroup,
  editingRows,
  startEditing,
  stopEditing,
  renderNestedRows,
  style: externalStyle,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: row.id,
    data: { parentPath },
  });

  const sortableStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: '#fff',
    borderRadius: 4,
    marginBottom: 4,
    cursor: 'grab',
    ...externalStyle,
  };

  // Render the label input (for non-account rows) – used as fallback.
  const renderLabel = () => {
    const displayText = row.label || ''; // For non-account rows, use label
    if (editingRows[row.id]) {
      return (
        <input
          type="text"
          defaultValue={displayText}
          autoFocus
          onBlur={(e) => {
            stopEditing(row.id);
            onLabelUpdate(row.id, e.target.value, parentPath);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              stopEditing(row.id);
              onLabelUpdate(row.id, e.target.value, parentPath);
            }
          }}
          style={{
            fontSize: row.rowType === 'subheading' ? '16px' : '14px',
            flexGrow: 1,
            padding: 4,
            border: '1px solid #ccc',
            borderRadius: 4,
          }}
        />
      );
    }
    return (
      <span onDoubleClick={() => startEditing(row.id)} style={{ cursor: 'pointer', flexGrow: 1 }}>
        {displayText}
      </span>
    );
  };

  // Render row content based on row type.
  const renderRowContent = () => {
    switch (row.rowType) {
      case 'subheading':
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Chevron to toggle collapse */}
            <div
              style={{ cursor: 'pointer', marginRight: 8 }}
              onClick={(e) => {
                e.stopPropagation();
                toggleCollapse(row.id);
              }}
            >
              <FontAwesomeIcon
                icon={collapsedBlocks[row.id] ? faChevronRight : faChevronDown}
                size="sm"
              />
            </div>
            {renderLabel()}
            {/* Show remove bin only if not top-level (i.e. parentPath not empty) */}
            {parentPath.length > 0 && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    window.confirm(
                      'Remove this group? Its underlying rows will be merged at the same level.'
                    )
                  ) {
                    onRemoveGroup(row.id, parentPath);
                  }
                }}
                style={{
                  marginLeft: 8,
                  cursor: 'pointer',
                  color: '#777',
                  padding: '2px 6px',
                  border: '1px solid #777',
                  borderRadius: 4,
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} size="xs" />
              </div>
            )}
          </div>
        );
      case 'formula':
        return (
          <div
            onDoubleClick={() => startEditing(row.id)}
            style={{ fontStyle: 'italic' }}
          >
            {renderLabel()}
          </div>
        );
      case 'total':
        return (
          <div onDoubleClick={() => startEditing(row.id)} style={{ fontWeight: 'bold' }}>
            {renderLabel()}
          </div>
        );
      case 'account':
      default:
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row.rowType === 'account' && (
              <input
                type="checkbox"
                checked={!!selectedRows[row.id]}
                onChange={(e) => {
                  e.stopPropagation();
                  onCheckboxChange(row.id, parentPath);
                }}
                style={{ marginRight: 8 }}
              />
            )}
            {row.accountCode ? (
              <span>
                {row.accountCode} -{' '}
                {editingRows[row.id] ? (
                  <input
                    type="text"
                    defaultValue={row.name || ''}
                    autoFocus
                    onBlur={(e) => {
                      stopEditing(row.id);
                      onLabelUpdate(row.id, e.target.value, parentPath);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        stopEditing(row.id);
                        onLabelUpdate(row.id, e.target.value, parentPath);
                      }
                    }}
                    style={{
                      fontSize: '14px',
                      padding: 4,
                      border: '1px solid #ccc',
                      borderRadius: 4,
                    }}
                  />
                ) : (
                  <span
                    onDoubleClick={() => startEditing(row.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.name || ''}
                  </span>
                )}
              </span>
            ) : (
              renderLabel()
            )}
          </div>
        );
    }
  };

  return (
    <div ref={setNodeRef} style={sortableStyle} {...attributes} {...listeners}>
      <div style={{ padding: 8, border: '1px solid #ccc', borderRadius: 4 }}>
        {renderRowContent()}
      </div>
      {row.rowType === 'subheading' && row.nestedRows && !collapsedBlocks[row.id] && (
        <div style={{ marginLeft: 25, marginTop: 4 }}>
          {renderNestedRows(row, parentPath)}
        </div>
      )}
    </div>
  );
}

/**
 * NestedSortableContext
 *
 * Renders the subheading's child rows in a SortableContext (for drag reordering).
 * The total row is shown below the child items and is not draggable.
 */
function NestedSortableContext({
  parentBlock,
  parentPath,
  collapsedBlocks,
  toggleCollapse,
  selectedRows,
  onCheckboxChange,
  onLabelUpdate,
  onRemoveGroup,
  editingRows,
  startEditing,
  stopEditing,
}) {
  const draggableRows =
    parentBlock.nestedRows?.filter((r) => r.rowType !== 'total') || [];
  const totalRow = parentBlock.nestedRows?.find((r) => r.rowType === 'total');

  const renderNestedRows = (block, path) => {
    return (
      <NestedSortableContext
        parentBlock={block}
        parentPath={[...path, block.id]}
        collapsedBlocks={collapsedBlocks}
        toggleCollapse={toggleCollapse}
        selectedRows={selectedRows}
        onCheckboxChange={onCheckboxChange}
        onLabelUpdate={onLabelUpdate}
        onRemoveGroup={onRemoveGroup}
        editingRows={editingRows}
        startEditing={startEditing}
        stopEditing={stopEditing}
      />
    );
  };

  return (
    <>
      <SortableContext items={draggableRows.map((r) => r.id)} strategy={verticalListSortingStrategy}>
        {draggableRows.map((childRow) => (
          <SortableRow
            key={childRow.id}
            row={childRow}
            parentPath={parentPath}
            collapsedBlocks={collapsedBlocks}
            toggleCollapse={toggleCollapse}
            selectedRows={selectedRows}
            onCheckboxChange={onCheckboxChange}
            onLabelUpdate={onLabelUpdate}
            onRemoveGroup={onRemoveGroup}
            editingRows={editingRows}
            startEditing={startEditing}
            stopEditing={stopEditing}
            renderNestedRows={renderNestedRows}
          />
        ))}
      </SortableContext>
      {totalRow && (
        <div
          style={{
            marginTop: 4,
            backgroundColor: '#f9f9f9',
            borderRadius: 4,
            padding: 6,
            border: '1px solid #ccc',
            fontWeight: 'bold',
          }}
        >
          {totalRow.label}
        </div>
      )}
    </>
  );
}

/**
 * Main XeroLayoutEditor
 *
 * Uses DnD Kit for nested drag & drop plus the “New Group” creation,
 * inline editing, and group removal functionality.
 */
export default function XeroLayoutEditor() {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, integrationId } = useParams();
  const { organisations, xeroIntegrations } = useAuth();

  const [organisation, setOrganisation] = useState(null);
  const [integration, setIntegration] = useState(null);
  const [blocks, setBlocks] = useState([]); // top-level layout blocks
  const [accountsMap, setAccountsMap] = useState(new Map());
  const [error, setError] = useState(null);

  // collapse state, subheading id => bool
  const [collapsedBlocks, setCollapsedBlocks] = useState({});
  // checkboxes for "New Group" creation
  const [selectedRows, setSelectedRows] = useState({});
  const [groupCounter, setGroupCounter] = useState(1);
  // Editing state; editingRows: row id => boolean
  const [editingRows, setEditingRows] = useState({});

  // Derive layout type from path
  const layoutType = useMemo(() => {
    if (location.pathname.includes('edit-pl-layout')) return 'pl';
    if (location.pathname.includes('edit-bs-layout')) return 'bs';
    if (location.pathname.includes('edit-cf-direct-layout')) return 'cf';
    return 'pl';
  }, [location.pathname]);

  useEffect(() => {
    if (!organisations || !xeroIntegrations) return;
    const foundOrg = organisations.find((o) => String(o.id) === String(orgId));
    if (!foundOrg) {
      setError('Organisation not found.');
      return;
    }
    setOrganisation(foundOrg);

    const foundIntegration = xeroIntegrations[foundOrg.id]?.find(
      (xi) => String(xi.id) === String(integrationId)
    );
    if (!foundIntegration) {
      setError('Xero Integration not found.');
      return;
    }
    setIntegration(foundIntegration);
  }, [orgId, integrationId, organisations, xeroIntegrations]);

  // Parse layout & accounts
  useEffect(() => {
    if (!integration) return;
    try {
      const key =
        layoutType === 'pl'
          ? 'plLayout'
          : layoutType === 'bs'
          ? 'bsLayout'
          : 'cfDirectLayout';
      const data = JSON.parse(integration[key] || '[]');
      if (!Array.isArray(data)) {
        console.error(`Expected ${key} array, got:`, data);
        setBlocks([]);
        return;
      }
      setBlocks(data);
    } catch (err) {
      console.error('Error parsing layout data =>', err);
      setBlocks([]);
    }

    try {
      let parsed = [];
      if (typeof integration.accounts === 'string') {
        const temp = JSON.parse(integration.accounts);
        parsed = Array.isArray(temp.accounts) ? temp.accounts : [];
      } else if (Array.isArray(integration.accounts?.accounts)) {
        parsed = integration.accounts.accounts;
      } else if (Array.isArray(integration.accounts)) {
        parsed = integration.accounts;
      }
      const m = new Map();
      parsed.forEach((acc) => {
        if (acc.accountCode) {
          m.set(acc.accountCode, acc);
        }
      });
      setAccountsMap(m);
    } catch (err) {
      console.error('Error parsing accounts =>', err);
      setAccountsMap(new Map());
    }
  }, [integration, layoutType]);

  // DnDKit sensors
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );

  // Handle drag end logic (unchanged from before)
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const activeContainer = active.data?.current?.parentPath || [];
    const overContainer = over.data?.current?.parentPath || [];

    if (JSON.stringify(activeContainer) !== JSON.stringify(overContainer)) {
      const newState = structuredClone(blocks);
      function findBlockByPath(arr, path) {
        if (!path.length) return null;
        const [currentId, ...rest] = path;
        const sub = arr.find((b) => b.id === currentId);
        if (!sub) return null;
        if (!rest.length) return sub;
        if (!sub.nestedRows) return null;
        return findBlockByPath(sub.nestedRows, rest);
      }
      const sourceSub = findBlockByPath(newState, activeContainer);
      const destSub = findBlockByPath(newState, overContainer);
      if (!sourceSub || !destSub) return;

      const sourceRows = sourceSub.nestedRows?.filter((r) => r.rowType !== 'total') || [];
      const destRows = destSub.nestedRows?.filter((r) => r.rowType !== 'total') || [];

      const srcIndex = sourceRows.findIndex((r) => r.id === active.id);
      const destIndex = destRows.findIndex((r) => r.id === over.id);
      if (srcIndex === -1) return;
      const [moved] = sourceRows.splice(srcIndex, 1);
      const insertIndex = destIndex === -1 ? destRows.length : destIndex;
      destRows.splice(insertIndex, 0, moved);

      const sTotal = sourceSub.nestedRows?.find((r) => r.rowType === 'total');
      if (sTotal) {
        sourceSub.nestedRows = [...sourceRows, sTotal];
      } else {
        sourceSub.nestedRows = sourceRows;
      }
      const dTotal = destSub.nestedRows?.find((r) => r.rowType === 'total');
      if (dTotal) {
        destSub.nestedRows = [...destRows, dTotal];
      } else {
        destSub.nestedRows = destRows;
      }

      setBlocks(newState);
    } else {
      const newState = structuredClone(blocks);
      function findBlockByPath(arr, path) {
        if (!path.length) return null;
        const [currentId, ...rest] = path;
        const sub = arr.find((b) => b.id === currentId);
        if (!sub) return null;
        if (!rest.length) return sub;
        if (!sub.nestedRows) return null;
        return findBlockByPath(sub.nestedRows, rest);
      }
      if (!activeContainer.length) {
        const fromIndex = newState.findIndex((b) => b.id === active.id);
        const toIndex = newState.findIndex((b) => b.id === over.id);
        setBlocks(arrayMove(newState, fromIndex, toIndex));
        return;
      }
      const sub = findBlockByPath(newState, activeContainer);
      if (!sub) return;
      const subRows = sub.nestedRows?.filter((r) => r.rowType !== 'total') || [];
      const fromIndex = subRows.findIndex((r) => r.id === active.id);
      const toIndex = subRows.findIndex((r) => r.id === over.id);
      if (fromIndex === -1 || toIndex === -1) return;
      const re = arrayMove(subRows, fromIndex, toIndex);
      const totalRow = sub.nestedRows?.find((r) => r.rowType === 'total');
      sub.nestedRows = totalRow ? [...re, totalRow] : re;
      setBlocks(newState);
    }
  };

  // Helper function: recursively update a row's label in blocks.
  // For 'account' rows, update row.name; otherwise, update row.label.
  const updateRowLabel = (rowId, newLabel, parentPath) => {
    const updateRecursive = (arr, path) => {
      for (let i = 0; i < arr.length; i++) {
        const row = arr[i];
        if (row.id === rowId) {
          if (row.rowType === 'account') {
            row.name = newLabel;
          } else {
            row.label = newLabel;
          }
          return true;
        }
        if (row.nestedRows && row.nestedRows.length) {
          if (updateRecursive(row.nestedRows, path.concat(row.id))) return true;
        }
      }
      return false;
    };
    const newState = structuredClone(blocks);
    updateRecursive(newState, []);
    setBlocks(newState);
  };

  // Helper function: remove a group (subheading) by dumping its nestedRows at the parent's level.
  const removeGroup = (groupId, parentPath) => {
    const removeRecursive = (arr, path) => {
      for (let i = 0; i < arr.length; i++) {
        const row = arr[i];
        if (row.id === groupId && row.rowType === 'subheading') {
          // Remove the group but merge its nestedRows at this level.
          const underlying = row.nestedRows || [];
          arr.splice(i, 1, ...underlying);
          return true;
        }
        if (row.nestedRows && row.nestedRows.length) {
          if (removeRecursive(row.nestedRows, path.concat(row.id))) return true;
        }
      }
      return false;
    };
    const newState = structuredClone(blocks);
    removeRecursive(newState, []);
    setBlocks(newState);
  };

  // handle save
  const handleSave = async () => {
    if (!integration) return;
    const newLayout = blocks;
    const payload = {
      integrationId: integration.id,
      ...(layoutType === 'pl' && { plLayout: newLayout }),
      ...(layoutType === 'bs' && { bsLayout: newLayout }),
      ...(layoutType === 'cf' && { cfDirectLayout: newLayout }),
    };

    let endpoint = '/xero/update-pl-layout';
    if (layoutType === 'bs') endpoint = '/xero/update-bs-layout';
    else if (layoutType === 'cf') endpoint = '/xero/update-cf-direct-layout';

    try {
      const resp = await api.post(endpoint, payload, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (resp.status === 200) {
        alert(
          `${
            layoutType === 'pl'
              ? 'P&L'
              : layoutType === 'bs'
              ? 'Balance Sheet'
              : 'Direct Cash Flow'
          } layout saved successfully!`
        );
        let path = `/organisation/${orgId}/xeropandl/${integrationId}`;
        if (layoutType === 'bs') path = `/organisation/${orgId}/xerobs/${integrationId}`;
        if (layoutType === 'cf') path = `/organisation/${orgId}/xerocf/${integrationId}`;
        navigate(path);
        window.location.reload();
      }
    } catch (err) {
      if (err.response) {
        console.error('Backend error:', err.response.data);
        alert(`Failed to save layout: ${err.response.data.error || 'Unknown error'}`);
      } else if (err.request) {
        console.error('No response from server:', err.request);
        alert('Failed to save layout: No response from server.');
      } else {
        console.error('Error setting up request:', err.message);
        alert(`Failed to save layout: ${err.message}`);
      }
    }
  };

  // Toggle subheading collapse
  const toggleCollapse = (id) => {
    setCollapsedBlocks((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // handle checkbox
  const handleCheckboxChange = (rowId, parentPath) => {
    setSelectedRows((prev) => {
      const copy = { ...prev };
      if (copy[rowId]) {
        delete copy[rowId];
      } else {
        copy[rowId] = parentPath;
      }
      return copy;
    });
  };

  // Editing helpers
  const startEditing = (rowId) => {
    setEditingRows((prev) => ({ ...prev, [rowId]: true }));
  };
  const stopEditing = (rowId) => {
    setEditingRows((prev) => {
      const copy = { ...prev };
      delete copy[rowId];
      return copy;
    });
  };

  // compute canCreateNewGroup
  const canCreateNewGroup = useMemo(() => {
    const ids = Object.keys(selectedRows);
    if (ids.length < 2) return false;
    const paths = Object.values(selectedRows);
    const first = JSON.stringify(paths[0]);
    return paths.every((p) => JSON.stringify(p) === first);
  }, [selectedRows]);

  // handle create new group
  const handleCreateNewGroup = () => {
    const ids = Object.keys(selectedRows);
    if (ids.length < 2) return;
    const commonPath = selectedRows[ids[0]];
    if (!commonPath || !commonPath.length) {
      alert('Invalid parent path for new group. Are all rows in the same subheading?');
      return;
    }
    const subheadingId = commonPath[commonPath.length - 1];
    const newState = structuredClone(blocks);
    function groupRows(arr, targetId) {
      for (let i = 0; i < arr.length; i++) {
        const blk = arr[i];
        if (!blk || !blk.id) continue;
        if (blk.id === targetId) {
          const nestedRows = blk.nestedRows || [];
          let totalItem = null;
          const totalIndex = nestedRows.findIndex((x) => x.rowType === 'total');
          if (totalIndex !== -1) {
            totalItem = nestedRows[totalIndex];
          }
          const indices = nestedRows
            .map((r, index) => (ids.includes(r.id) ? index : null))
            .filter((ix) => ix !== null);
          if (!indices.length) return;
          const minIndex = Math.min(...indices);
          const toMove = nestedRows.filter((r) => ids.includes(r.id));
          if (toMove.length < 2) {
            alert('Select at least two rows in the same subheading.');
            return;
          }
          const uniqueGroupName = `New Group ${groupCounter}`;
          setGroupCounter((prev) => prev + 1);
          const newGroupRow = {
            rowType: 'subheading',
            label: uniqueGroupName,
            id: generateUUID(),
            nestedRows: toMove,
          };
          const remain = nestedRows.filter((r) => !ids.includes(r.id));
          remain.splice(minIndex, 0, newGroupRow);
          if (totalItem) {
            const withoutTotal = remain.filter((r) => r.rowType !== 'total');
            remain.length = 0;
            remain.push(...withoutTotal, totalItem);
          }
          blk.nestedRows = remain;
          return;
        }
        if (blk.nestedRows?.length) {
          groupRows(blk.nestedRows, targetId);
        }
      }
    }
    groupRows(newState, subheadingId);
    setBlocks(newState);
    setSelectedRows({});
    alert('New group created at the position of the top-most checked row!');
  };

  // Expand All / Collapse All helpers.
  const handleExpandAll = () => {
    const expandState = (blocksArray) => {
      let result = {};
      blocksArray.forEach((block) => {
        if (block.rowType === 'subheading') {
          result[block.id] = false;
          if (block.nestedRows && block.nestedRows.length > 0) {
            result = { ...result, ...expandState(block.nestedRows) };
          }
        }
      });
      return result;
    };
    setCollapsedBlocks(expandState(blocks));
  };

  const handleCollapseAll = () => {
    const collapseState = (blocksArray) => {
      let result = {};
      blocksArray.forEach((block) => {
        if (block.rowType === 'subheading') {
          result[block.id] = true;
          if (block.nestedRows && block.nestedRows.length > 0) {
            result = { ...result, ...collapseState(block.nestedRows) };
          }
        }
      });
      return result;
    };
    setCollapsedBlocks(collapseState(blocks));
  };

  // DnDKit top-level render
  return (
    <div style={{ padding: 20 }}>
      <h3>
        Edit{' '}
        {layoutType === 'pl'
          ? 'P&L'
          : layoutType === 'bs'
          ? 'Balance Sheet'
          : 'Direct Cash Flow'}{' '}
        Layout for {organisation?.name}
      </h3>
      <p style={{ marginBottom: 10, fontStyle: 'italic' }}>
        Please <strong>Collapse All</strong> before reordering main sections. Also, if you want to move a group please collapse it first.
      </p>
      <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center' }}>
        <button onClick={handleExpandAll} style={{ marginRight: 10 }}>
          Expand All
        </button>
        <button onClick={handleCollapseAll} style={{ marginRight: 10 }}>
          Collapse All
        </button>
      </div>
      <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center' }}>
        <button onClick={handleSave} style={{ marginRight: 10 }}>
          Save Changes
        </button>
        {canCreateNewGroup && (
          <button onClick={handleCreateNewGroup} style={{ marginRight: 10 }}>
            New Group
          </button>
        )}
        <button onClick={() => navigate(-1)}>Back</button>
      </div>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={blocks.map((b) => b.id)} strategy={verticalListSortingStrategy}>
          {blocks.map((block) => (
            <SortableRow
              key={block.id}
              row={block}
              parentPath={[]}
              collapsedBlocks={collapsedBlocks}
              toggleCollapse={toggleCollapse}
              selectedRows={selectedRows}
              onCheckboxChange={handleCheckboxChange}
              onLabelUpdate={updateRowLabel}
              onRemoveGroup={removeGroup}
              editingRows={editingRows}
              startEditing={startEditing}
              stopEditing={stopEditing}
              renderNestedRows={(b, path) => (
                <NestedSortableContext
                  parentBlock={b}
                  parentPath={[...path, b.id]}
                  collapsedBlocks={collapsedBlocks}
                  toggleCollapse={toggleCollapse}
                  selectedRows={selectedRows}
                  onCheckboxChange={handleCheckboxChange}
                  onLabelUpdate={updateRowLabel}
                  onRemoveGroup={removeGroup}
                  editingRows={editingRows}
                  startEditing={startEditing}
                  stopEditing={stopEditing}
                />
              )}
            />
          ))}
        </SortableContext>
      </DndContext>
      <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
        <button onClick={handleSave} style={{ marginRight: 10 }}>
          Save Changes
        </button>
        {canCreateNewGroup && (
          <button onClick={handleCreateNewGroup} style={{ marginRight: 10 }}>
            New Group
          </button>
        )}
        <button onClick={() => navigate(-1)}>Back</button>
      </div>
    </div>
  );
}
