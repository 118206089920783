import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../authcontext';
import api from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const XeroDirectCashFlowPage = () => {
  const { id, orgId } = useParams(); // Adjusted to include orgId if routing is updated
  const navigate = useNavigate();
  const { organisations, xeroIntegrations, isLoading, isLoggedIn } = useAuth();

  // Local state
  const [organisation, setOrganisation] = useState(null);
  const [integration, setIntegration] = useState(null);
  const [error, setError] = useState(null);
  const [allAccounts, setAllAccounts] = useState([]);
  const [cfRows, setCfRows] = useState([]);
  // Collapse state: key is row ID; value is boolean (true if collapsed)
  const [collapsedGroups, setCollapsedGroups] = useState({});

  // Calculate monthly periods (24 months)
  const monthlyPeriods = useMemo(() => {
    return calculateMonthlyPeriods(new Date(), 24);
  }, []);

  // Helper: Calculate 24 monthly periods
  function calculateMonthlyPeriods(currentDate, histPeriods = 24) {
    const periods = [];
    let date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    for (let i = 0; i < histPeriods; i++) {
      const label = date.toLocaleString('default', { month: '2-digit', year: 'numeric' });
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1)
        .toISOString()
        .split('T')[0];
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        .toISOString()
        .split('T')[0];
      periods.unshift({ label, startDate, endDate });
      date.setMonth(date.getMonth() - 1);
    }
    return periods;
  }

  // Toggle collapse for a group (subheading) row
  const toggleCollapse = (rowId) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  // Fetch organisation + Xero integration details
  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      if (orgId) {
        const orgIdInt = parseInt(orgId, 10);
        const foundOrg = organisations.find((org) => org.id === orgIdInt);
        if (foundOrg) {
          setOrganisation(foundOrg);
          setError(null);
          const foundIntegration = xeroIntegrations[foundOrg.id]?.find(
            (xi) => String(xi.id) === String(id) && xi.connectionStatus === 'active'
          );
          if (foundIntegration) {
            setIntegration(foundIntegration);
          } else {
            setError('Xero Integration not found or inactive.');
          }
        } else {
          setError('Organisation not found in your data.');
        }
      } else {
        const foundOrg = organisations.find((org) =>
          xeroIntegrations[org.id]?.some((xi) => String(xi.id) === String(id))
        );
        if (foundOrg) {
          setOrganisation(foundOrg);
          setError(null);
          const foundIntegration = xeroIntegrations[foundOrg.id]?.find(
            (xi) => String(xi.id) === String(id) && xi.connectionStatus === 'active'
          );
          if (foundIntegration) {
            setIntegration(foundIntegration);
          } else {
            setError('Xero Integration not found or inactive.');
          }
        } else {
          setError('Organisation not found in your data.');
        }
      }
    }
  }, [id, orgId, organisations, xeroIntegrations, isLoading, isLoggedIn]);

  // Parse the layout + accounts from integration
  useEffect(() => {
    if (!integration) {
      setAllAccounts([]);
      setCfRows([]);
      return;
    }
    let parsedAccounts = [];
    try {
      const obj = JSON.parse(integration.accounts || '{}');
      if (Array.isArray(obj.accounts)) {
        parsedAccounts = obj.accounts;
      }
    } catch (err) {
      console.error('Error parsing accounts JSON:', err);
    }
    let cfDirectLayoutData = [];
    try {
      cfDirectLayoutData = JSON.parse(integration.cfDirectLayout || '[]');
    } catch (err) {
      console.error('Error parsing cfDirectLayout:', err);
      cfDirectLayoutData = [];
    }
    setAllAccounts(parsedAccounts);
    setCfRows(cfDirectLayoutData);
  }, [integration]);

  // Disconnect from Xero
  const handleDisconnectXero = async () => {
    try {
      const response = await api.post('/xero/disconnect', {
        organisation_id: organisation.id,
        xero_integration_id: integration.id,
      });
      if (response.data.message) {
        navigate(`/organisation/${organisation.id}`);
        window.location.reload();
      }
    } catch (err) {
      console.error('Error disconnecting from Xero:', err);
      alert('Failed to disconnect from Xero');
    }
  };

  /* --------------------- Value Formatting Helper --------------------- */
  // Returns a formatted string for a number; if near zero, returns "0.00".
  const formatValue = (val) => {
    const v = Number(val);
    if (Math.abs(v) < 0.005) return "0.00";
    return v.toFixed(2);
  };

  /* --------------------- Totals Helpers --------------------- */
  // Recursively compute totals for a group of rows, ignoring "total" rows.
  // Optionally, if not in the Change in Cash section, values are multiplied by -1.
  const computeGroupTotals = (rows, inChangeSection = false) => {
    const totals = {};
    monthlyPeriods.forEach((p) => {
      totals[p.label] = 0;
    });
    rows.forEach((row) => {
      if (row.rowType === 'account') {
        const acct = allAccounts.find((a) => String(a.id) === String(row.id));
        if (acct && acct.cashMonthlyValues) {
          monthlyPeriods.forEach((p) => {
            let val = Number(acct.cashMonthlyValues[p.label] || 0);
            // If not in "Change in Cash" section, flip sign.
            if (!inChangeSection) {
              val = -val;
            }
            totals[p.label] += val;
          });
        }
      } else if ((row.rowType === 'subheading' || row.rowType === 'subheading2') && row.nestedRows) {
        // If the current subheading is "Change in Cash", then set the flag.
        let newInChangeSection = inChangeSection;
        if (row.label.trim().toLowerCase() === "change in cash") {
          newInChangeSection = true;
        }
        const subTotals = computeGroupTotals(row.nestedRows.filter((r) => r.rowType !== 'total'), newInChangeSection);
        monthlyPeriods.forEach((p) => {
          totals[p.label] += Number(subTotals[p.label] || 0);
        });
      }
    });
    return totals;
  };

  // Helper: Compute totals for a top‑level group with a given label.
  const computeTotalsForGroupLabel = (label) => {
    const group = cfRows.find(
      (r) =>
        r.rowType === 'subheading' &&
        r.label.trim().toLowerCase() === label.trim().toLowerCase()
    );
    if (group && group.nestedRows) {
      return computeGroupTotals(group.nestedRows.filter((r) => r.rowType !== 'total'), 
          group.label.trim().toLowerCase() === "change in cash");
    }
    const zeros = {};
    monthlyPeriods.forEach((p) => {
      zeros[p.label] = 0;
    });
    return zeros;
  };

  // Compute totals for sections.
  const operatingTotals = computeTotalsForGroupLabel('Operating Activities');
  const investingTotals = computeTotalsForGroupLabel('Investing Activities');
  const financingTotals = computeTotalsForGroupLabel('Financing Activities');

  // Compute Net Cash Flow = sum of operating, investing, and financing.
  const computeNetTotals = () => {
    const net = {};
    monthlyPeriods.forEach((p) => {
      net[p.label] = (operatingTotals[p.label] || 0) + (investingTotals[p.label] || 0) + (financingTotals[p.label] || 0);
    });
    return net;
  };

  /* --------------------- Recursive Rendering --------------------- */
  /**
   * RenderRowsRecursively:
   * - The additional parameter inChangeSection indicates if the current row is in a "Change in Cash" section.
   * - If inChangeSection is false, all numeric values are multiplied by -1.
   */
  const renderRowsRecursively = (rows, indent = 0, inChangeSection = false, groupRows = rows.filter((r) => r.rowType !== 'total')) => {
    return rows.flatMap((row) => {
      const indentPx = indent * 20;
      let rendered = [];

      if (row.rowType === 'subheading' || row.rowType === 'subheading2') {
        // Check if this subheading is the "Change in Cash" section.
        let newInChangeSection = inChangeSection;
        if (row.label.trim().toLowerCase() === "change in cash") {
          newInChangeSection = true;
        }
        const thisGroupTotals = computeGroupTotals(
          row.nestedRows ? row.nestedRows.filter((r) => r.rowType !== 'total') : [],
          newInChangeSection
        );
        rendered.push(
          <tr
            key={row.id}
            style={{
              fontWeight: 'bold',
              backgroundColor: row.rowType === 'subheading' ? '#f7f7f7' : '#fafafa',
              borderBottom: '1px solid #e0e0e0'
            }}
          >
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 2,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                display: 'flex',
                alignItems: 'center',
                minWidth: '280px'
              }}
            >
              <span
                style={{ cursor: 'pointer', marginRight: '8px' }}
                onClick={() => toggleCollapse(row.id)}
              >
                <FontAwesomeIcon
                  icon={collapsedGroups[row.id] ? faChevronRight : faChevronDown}
                  size="sm"
                  color="#555"
                />
              </span>
              <span style={{ marginLeft: indentPx, whiteSpace: 'nowrap' }}>{row.label}</span>
            </td>
            {monthlyPeriods.map((period, i) => {
              let val = thisGroupTotals[period.label] || 0.0;
              // Ensure that if the value is very close to zero, we show 0.00 instead of -0.00.
              return (
                <td
                  key={i}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right'
                  }}
                >
                  {formatValue(val)}
                </td>
              );
            })}
          </tr>
        );
        if (!collapsedGroups[row.id] && row.nestedRows && row.nestedRows.length > 0) {
          rendered = rendered.concat(
            renderRowsRecursively(row.nestedRows, indent + 1, newInChangeSection, row.nestedRows.filter((r) => r.rowType !== 'total'))
          );
        }
      } else if (row.rowType === 'account') {
        const acct = allAccounts.find((a) => String(a.id) === String(row.id)) || {};
        rendered.push(
          <tr key={row.id}>
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 1,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                minWidth: '280px'
              }}
            >
              <span style={{ marginLeft: indentPx, whiteSpace: 'nowrap' }}>{row.name}</span>
            </td>
            {monthlyPeriods.map((period, i) => {
              let val = Number(acct.cashMonthlyValues?.[period.label] || 0);
              // If not in Change in Cash section, flip the sign.
              if (!inChangeSection) {
                val = -val;
              }
              return (
                <td
                  key={i}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right'
                  }}
                >
                  {formatValue(val)}
                </td>
              );
            })}
          </tr>
        );
      } else if (row.rowType === 'total') {
        const totals = computeGroupTotals(groupRows, inChangeSection);
        rendered.push(
          <tr key={row.id} style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 2,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                minWidth: '280px'
              }}
            >
              <span style={{ marginLeft: indentPx, whiteSpace: 'nowrap' }}>{row.label}</span>
            </td>
            {monthlyPeriods.map((period, i) => {
              let val = totals[period.label] || 0.0;
              return (
                <td
                  key={i}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right'
                  }}
                >
                  {formatValue(val)}
                </td>
              );
            })}
          </tr>
        );
      } else if (row.rowType === 'formula') {
        let computed = {};
        const lowerLabel = row.label.trim().toLowerCase();
        if (lowerLabel === 'net cash flow') {
          computed = computeNetTotals();
        }
        rendered.push(
          <tr key={row.id} style={{ fontWeight: 'bold', backgroundColor: '#e6f7ff' }}>
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 2,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                minWidth: '280px'
              }}
            >
              <span style={{ marginLeft: indentPx, whiteSpace: 'nowrap' }}>{row.label}</span>
            </td>
            {monthlyPeriods.map((_, i) => {
              let val = computed[_.label] !== undefined ? Number(computed[_.label]) : 0.0;
              return (
                <td
                  key={i}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right'
                  }}
                >
                  {formatValue(val)}
                </td>
              );
            })}
          </tr>
        );
      } else if (row.rowType === 'text') {
        rendered.push(
          <tr key={row.id} style={{ fontStyle: 'italic', backgroundColor: '#fff' }}>
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 2,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                minWidth: '280px'
              }}
            >
              <span style={{ marginLeft: indentPx, whiteSpace: 'nowrap' }}>{row.label}</span>
            </td>
            {monthlyPeriods.map((_, i) => (
              <td
                key={i}
                style={{
                  border: '1px solid #e0e0e0',
                  padding: '8px 10px',
                  textAlign: 'right'
                }}
              ></td>
            ))}
          </tr>
        );
      }
      return rendered;
    });
  };

  // Handle loading and errors
  if (isLoading) return <p>Loading global data...</p>;
  if (error) return <p>{error}</p>;
  if (!organisation) return <p>Organisation not found.</p>;
  if (!integration) return <p>Xero Integration not found or inactive.</p>;

  // Define a common button style for a subtle, slightly darker blue design with black text
  const subtleBlueButtonStyle = {
    marginRight: '10px',
    padding: '8px 12px',
    border: 'none',
    backgroundColor: '#dbdbdb',
    color: '#000',
    cursor: 'pointer',
    borderRadius: '4px'
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', color: '#333' }}>
      <h2 style={{ marginBottom: '10px' }}>{organisation.name}</h2>
      <div style={{ marginBottom: '10px' }}>
        <Link to={`/organisation/${organisation.id}/activity-log`} style={{ marginRight: '15px', color: '#007bff', textDecoration: 'none' }}>
          Activity Log
        </Link>
        <Link to={`/organisation/${organisation.id}/start-subscription`} style={{ color: '#007bff', textDecoration: 'none' }}>
          Start Subscription
        </Link>
      </div>
      <p style={{ margin: '5px 0' }}>Created By: {organisation.createdBy}</p>
      <p style={{ margin: '5px 0' }}>Stripe ID: {organisation.stripeId}</p>
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <button
          style={subtleBlueButtonStyle}
          onClick={() => navigate(`/organisation/${organisation.id}`)}
        >
          Back to Organisation
        </button>
        <button
          style={subtleBlueButtonStyle}
          onClick={() =>
            navigate(`/organisation/${organisation.id}/xero/${integration.id}/edit-cf-direct-layout`)
          }
        >
          Edit Direct Cash Flow Layout
        </button>
        <button
          style={{ ...subtleBlueButtonStyle, marginLeft: '10px' }}
          onClick={handleDisconnectXero}
        >
          Disconnect Xero
        </button>
      </div>

      {/* The Cash Flow table with horizontal scrolling */}
      <div style={{ maxHeight: '600px', overflowY: 'auto', overflowX: 'auto' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ borderBottom: '2px solid #ccc', backgroundColor: '#f0f0f0' }}>
              <th
                style={{
                  border: '1px solid #e0e0e0',
                  padding: '8px 10px',
                  textAlign: 'left',
                  position: 'sticky',
                  top: 0,
                  left: '-2px',
                  backgroundColor: '#f0f0f0',
                  zIndex: 3,
                  minWidth: '300px'
                }}
              >
                Name
              </th>
              {monthlyPeriods.map((period) => (
                <th
                  key={period.label}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right',
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#f0f0f0'
                  }}
                >
                  {period.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{renderRowsRecursively(cfRows, 0, false)}</tbody>
        </table>
      </div>

      <div style={{ marginTop: '20px' }}>
        <h3 style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>QuickBooks</h3>
        <p>To be implemented...</p>
      </div>
    </div>
  );
};

export default XeroDirectCashFlowPage;
