import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authcontext';
import api from './api';

function Navigation() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found in localStorage');
      }

      console.log('Sending logout request with token:', token); // Debugging log

      await api.post(
        '/logout',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('Logout response: success'); // Debugging log

      logout();
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      navigate('/'); // Redirect to login page after logout
    } catch (error) {
      console.error('Logout failed:', error);
      alert('Logout failed. Please try again.');
      // Optionally force logout by clearing tokens and redirecting
      logout();
      navigate('/');
    }
  };

  const handleMyAccount = () => {
    navigate('/account'); // Adjust the path as needed
  };

  const handleManageOrganisations = () => {
    navigate('/organisations'); // Adjust the path as needed
  };

  return (
    <nav>
      <div className='nav-dropdown-section'>
        {/* Existing Navigation Buttons */}
        <div className="nav-dropdown">
          <button className='nav-button' onClick={() => navigate('/home')}>Home</button>
        </div>

        {/* New Profile Dropdown */}
        <div className="nav-dropdown">
          <button
            className='nav-button'
            onClick={handleMyAccount}
          >
            Profile
          </button>
          <div className='nav-dropdown-content'>
            <button className='nav-dropdown-button' onClick={handleMyAccount}>
              My Account
            </button>
            <button className='nav-dropdown-button' onClick={handleManageOrganisations}>
              Manage Organisations
            </button>
            <button className='nav-dropdown-button' onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
