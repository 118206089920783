import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../authcontext';
import api from '../api'; // Assuming you have an API module

const XeroJournalDetailsPage = () => {
  const { orgId, integrationId, type, journalId } = useParams(); // Include 'type' in params
  const navigate = useNavigate();
  const { organisations, xeroIntegrations, isLoading, isLoggedIn } = useAuth();

  // Local state
  const [organisation, setOrganisation] = useState(null);
  const [integration, setIntegration] = useState(null);
  const [journal, setJournal] = useState(null);
  const [accounts, setAccounts] = useState([]); // State to store accounts data
  const [error, setError] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false); // Track data loading

  // Helper: Parse date from Xero's format
  const parseXeroDate = (xeroDateStr) => {
    try {
      const timestampMatch = xeroDateStr.match(/\/Date\((\d+)[+-]\d+\)\//);
      if (timestampMatch && timestampMatch[1]) {
        const timestamp = parseInt(timestampMatch[1], 10);
        const date = new Date(timestamp);
        return date.toLocaleDateString();
      } else {
        throw new Error("Invalid date format");
      }
    } catch (e) {
      console.error('Error parsing date:', e);
      return 'Invalid Date';
    }
  };

  // Fetch organisation, integration, journal, and accounts data
  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      const orgIdInt = parseInt(orgId, 10);
      const foundOrg = organisations.find((org) => org.id === orgIdInt);

      if (foundOrg) {
        setOrganisation(foundOrg);
        const foundIntegration = xeroIntegrations[foundOrg.id]?.find(
          (xi) => String(xi.id) === String(integrationId) && xi.connectionStatus === 'active'
        );

        if (foundIntegration) {
          setIntegration(foundIntegration);
          // Fetch journals based on 'type'
          try {
            const journalsJSON = type === 'cash' ? foundIntegration.cashJournals : foundIntegration.accrualJournals;
            const parsedJournals = JSON.parse(journalsJSON || '{"Journals": []}');
            const journals = parsedJournals.Journals || [];
            const foundJournal = journals.find((j) => String(j.JournalID) === String(journalId));

            if (foundJournal) {
              setJournal(foundJournal);
            } else {
              setError('Journal not found.');
            }

            // Fetch accounts data
            const accountsJSON = foundIntegration.accounts;
            if (accountsJSON) {
              const parsedAccounts = JSON.parse(accountsJSON);
              const accountsList = parsedAccounts.accounts || [];
              setAccounts(accountsList);
            } else {
              console.error('No accounts data found in integration.');
              setError('Accounts data not available.');
            }

            setDataLoaded(true); // Data has been loaded
          } catch (err) {
            console.error('Error parsing journals or accounts JSON:', err);
            setError('Failed to parse journal or accounts data.');
            setDataLoaded(true); // Data has been loaded (with error)
          }
        } else {
          setError('Xero Integration not found or inactive.');
          setDataLoaded(true);
        }
      } else {
        setError('Organisation not found.');
        setDataLoaded(true);
      }
    }
  }, [orgId, integrationId, type, journalId, organisations, xeroIntegrations, isLoading, isLoggedIn]);

  // Handle Disconnect
  const handleDisconnectXero = async () => {
    try {
      const response = await api.post('/xero/disconnect', {
        organisation_id: organisation.id,
        xero_integration_id: integration.id,
      });
      if (response.data.message) {
        navigate(`/organisation/${organisation.id}`);
        window.location.reload();
      }
    } catch (err) {
      console.error('Error disconnecting from Xero:', err);
      alert('Failed to disconnect from Xero');
    }
  };

  // Function to get typeGroup based on AccountCode
  const getTypeGroup = (accountCode) => {
    const account = accounts.find((acc) => acc.accountCode === accountCode);
    return account ? account.typeGroup : 'N/A';
  };

  // Render Journal Details Table with Debit and Credit Columns
  const renderJournalDetails = () => {
    if (!journal) return <p>Journal data is not available.</p>;

    return (
      <table className="data-table">
        <thead>
          <tr>
            <th>Account Code</th>
            <th>Account Name</th>
            <th>Type Group</th>
            <th>Description</th>
            <th>Debit</th>
            <th>Credit</th>
          </tr>
        </thead>
        <tbody>
          {journal.JournalLines.map((line) => {
            let debit = 0;
            let credit = 0;
            const netAmount = line.NetAmount;

            if (netAmount > 0) {
              debit = netAmount;
            } else if (netAmount < 0) {
              credit = Math.abs(netAmount);
            }
            // If netAmount is 0, both debit and credit remain 0

            const typeGroup = getTypeGroup(line.AccountCode);

            return (
              <tr key={line.JournalLineID}>
                <td>{line.AccountCode}</td>
                <td>{line.AccountName}</td>
                <td>{typeGroup}</td>
                <td>{line.Description || 'N/A'}</td>
                <td>{debit !== 0 ? debit.toFixed(2) : '-'}</td>
                <td>{credit !== 0 ? credit.toFixed(2) : '-'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  // Calculate Totals for Validation
  const calculateTotals = () => {
    if (!journal) return { totalDebit: 0, totalCredit: 0 };
    let totalDebit = 0;
    let totalCredit = 0;

    journal.JournalLines.forEach((line) => {
      const netAmount = line.NetAmount;
      if (netAmount > 0) {
        totalDebit += netAmount;
      } else if (netAmount < 0) {
        totalCredit += Math.abs(netAmount);
      }
    });

    return { totalDebit, totalCredit };
  };

  const { totalDebit, totalCredit } = calculateTotals();

  // Handle Loading and Errors
  if (isLoading || !dataLoaded) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!organisation) return <p>Organisation not found.</p>;
  if (!integration) return <p>Xero Integration not found or inactive.</p>;
  if (!journal) return <p>Journal not found.</p>;

  // Optional: Log journal and accounts data for debugging
  console.log('Journal:', journal);
  console.log('Accounts:', accounts);

  return (
    <div>
      <h2>Journal Details for {organisation.name}</h2>
      <div>
        <button onClick={() => navigate(-1)}>Back to Journals</button>
        <button onClick={handleDisconnectXero}>Disconnect Xero</button>
      </div>
      <div>
        <p><strong>Journal Date:</strong> {parseXeroDate(journal.JournalDate)}</p>
        <p><strong>Journal Number:</strong> {journal.JournalNumber}</p>
        <p><strong>Source Type:</strong> {journal.SourceType}</p>
      </div>
      <h3>Journal Lines</h3>
      {journal.JournalLines.length > 0 ? renderJournalDetails() : <p>No Journal Lines available.</p>}
      
      {/* Display Totals */}
      <div>
        <p><strong>Total Debit:</strong> {totalDebit.toFixed(2)}</p>
        <p><strong>Total Credit:</strong> {totalCredit.toFixed(2)}</p>
        {totalDebit === totalCredit ? (
          <p>Balanced</p>
        ) : (
          <p>Unbalanced</p>
        )}
      </div>

      <Link to={`/organisation/${orgId}`}>Back to Organisation</Link>
    </div>
  );
};

export default XeroJournalDetailsPage;
