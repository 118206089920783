import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../authcontext';
import api from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const XeroBalanceSheetPage = () => {
  const { id, orgId } = useParams(); // Adjusted to include orgId if routing is updated
  const navigate = useNavigate();
  const { organisations, xeroIntegrations, isLoading, isLoggedIn } = useAuth();

  // Local state
  const [organisation, setOrganisation] = useState(null);
  const [integration, setIntegration] = useState(null);
  const [error, setError] = useState(null);
  const [allAccounts, setAllAccounts] = useState([]);
  const [bsRows, setBsRows] = useState([]);
  // Collapse state: key is row ID; value is boolean (true if collapsed)
  const [collapsedGroups, setCollapsedGroups] = useState({});

  // Calculate monthly periods (24 months)
  const monthlyPeriods = useMemo(() => {
    return calculateMonthlyPeriods(new Date(), 24);
  }, []);

  // Helper: Calculate 24 monthly periods
  function calculateMonthlyPeriods(currentDate, histPeriods = 24) {
    const periods = [];
    let date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    for (let i = 0; i < histPeriods; i++) {
      const label = date.toLocaleString('default', { month: '2-digit', year: 'numeric' });
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];
      periods.unshift({ label, startDate, endDate });
      date.setMonth(date.getMonth() - 1);
    }
    return periods;
  }

  // Toggle collapse for a group (subheading) row
  const toggleCollapse = (rowId) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  // Fetch organisation + Xero integration details
  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      if (orgId) {
        const orgIdInt = parseInt(orgId, 10);
        const foundOrg = organisations.find((org) => org.id === orgIdInt);
        if (foundOrg) {
          setOrganisation(foundOrg);
          setError(null);
          const foundIntegration = xeroIntegrations[foundOrg.id]?.find(
            (xi) => String(xi.id) === String(id) && xi.connectionStatus === 'active'
          );
          if (foundIntegration) {
            setIntegration(foundIntegration);
          } else {
            setError('Xero Integration not found or inactive.');
          }
        } else {
          setError('Organisation not found in your data.');
        }
      } else {
        const foundOrg = organisations.find((org) =>
          xeroIntegrations[org.id]?.some((xi) => String(xi.id) === String(id))
        );
        if (foundOrg) {
          setOrganisation(foundOrg);
          setError(null);
          const foundIntegration = xeroIntegrations[foundOrg.id]?.find(
            (xi) => String(xi.id) === String(id) && xi.connectionStatus === 'active'
          );
          if (foundIntegration) {
            setIntegration(foundIntegration);
          } else {
            setError('Xero Integration not found or inactive.');
          }
        } else {
          setError('Organisation not found in your data.');
        }
      }
    }
  }, [id, orgId, organisations, xeroIntegrations, isLoading, isLoggedIn]);

  // Parse the layout + accounts from integration
  useEffect(() => {
    if (!integration) {
      setAllAccounts([]);
      setBsRows([]);
      return;
    }
    let parsedAccounts = [];
    try {
      const obj = JSON.parse(integration.accounts || '{}');
      if (Array.isArray(obj.accounts)) {
        parsedAccounts = obj.accounts;
      }
    } catch (err) {
      console.error('Error parsing accounts JSON:', err);
    }
    let bsLayoutData = [];
    try {
      bsLayoutData = JSON.parse(integration.bsLayout || '[]');
    } catch (err) {
      console.error('Error parsing plLayout:', err);
      bsLayoutData = [];
    }
    setAllAccounts(parsedAccounts);
    setBsRows(bsLayoutData);
  }, [integration]);

  // Disconnect from Xero
  const handleDisconnectXero = async () => {
    try {
      const response = await api.post('/xero/disconnect', {
        organisation_id: organisation.id,
        xero_integration_id: integration.id,
      });
      if (response.data.message) {
        navigate(`/organisation/${organisation.id}`);
        window.location.reload();
      }
    } catch (err) {
      console.error('Error disconnecting from Xero:', err);
      alert('Failed to disconnect from Xero');
    }
  };

  /* --------------------- Totals Helpers --------------------- */
  // Recursively compute totals for a group of rows, ignoring "total" rows.
  const computeGroupTotals = (rows) => {
    const totals = {};
    monthlyPeriods.forEach((p) => {
      totals[p.label] = 0;
    });
    rows.forEach((row) => {
      if (row.rowType === 'account') {
        const acct = allAccounts.find((a) => String(a.id) === String(row.id));
        if (acct && acct.allMonthlyValues) {
          monthlyPeriods.forEach((p) => {
            totals[p.label] += Number(acct.allMonthlyValues[p.label] || 0);
          });
        }
      } else if ((row.rowType === 'subheading' || row.rowType === 'subheading2') && row.nestedRows) {
        const subTotals = computeGroupTotals(row.nestedRows.filter((r) => r.rowType !== 'total'));
        monthlyPeriods.forEach((p) => {
          totals[p.label] += Number(subTotals[p.label] || 0);
        });
      }
    });
    return totals;
  };

  // Helper: Compute totals for a top‑level group with a given label (case‑insensitive).
  const computeTotalsForGroupLabel = (label) => {
    const group = bsRows.find(
      (r) =>
        r.rowType === 'subheading' &&
        r.label.trim().toLowerCase() === label.trim().toLowerCase()
    );
    if (group && group.nestedRows) {
      return computeGroupTotals(
        group.nestedRows.filter((r) => r.rowType !== 'total')
      );
    }
    const zeros = {};
    monthlyPeriods.forEach((p) => {
      zeros[p.label] = 0;
    });
    return zeros;
  };
  
  const assetsTotals = computeTotalsForGroupLabel('Assets');
  const liabilitiesTotals = computeTotalsForGroupLabel('Liabilities');
  const equityTotals = computeTotalsForGroupLabel('Equity');

  // Gross Profit = Income - Cost of Sales
  const computeTotalLETotals = () => {
    const gp = {};
    monthlyPeriods.forEach((p) => {
      gp[p.label] = (liabilitiesTotals[p.label] || 0) + (equityTotals[p.label] || 0);
    });
    return gp;
  };

  /* --------------------- Recursive Rendering --------------------- */
  // This function renders all rows. Subheading rows get chevrons + group totals in their monthly columns.
  const renderRowsRecursively = (rows, indent = 0, groupRows = rows.filter((r) => r.rowType !== 'total')) => {
    return rows.flatMap((row) => {
      const indentPx = indent * 20;
      let rendered = [];

      // If subheading (or subheading2), we add a chevron + group totals in the monthly columns
      if (row.rowType === 'subheading' || row.rowType === 'subheading2') {
        // Compute group totals for this subheading
        const thisGroupTotals = computeGroupTotals(row.nestedRows ? row.nestedRows.filter((r) => r.rowType !== 'total') : []);
        
        rendered.push(
          <tr
            key={row.id}
            style={{
              fontWeight: 'bold',
              backgroundColor: row.rowType === 'subheading' ? '#f7f7f7' : '#fafafa',
              borderBottom: '1px solid #e0e0e0'
            }}
          >
            {/* Sticky left cell with chevron + name */}
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 2,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                display: 'flex',
                alignItems: 'center',
                minWidth: '280px'
              }}
            >
              <span
                style={{ cursor: 'pointer', marginRight: '8px' }}
                onClick={() => toggleCollapse(row.id)}
              >
                <FontAwesomeIcon
                  icon={collapsedGroups[row.id] ? faChevronRight : faChevronDown}
                  size="sm"
                  color="#555"
                />
              </span>
              <span style={{ marginLeft: indentPx, whiteSpace: 'nowrap' }}>{row.label}</span>
              </td>
            {/* Display computed totals for each month in the subheading's monthly columns */}
            {monthlyPeriods.map((period, i) => {
              const val = thisGroupTotals[period.label] || 0.0;
              return (
                <td
                  key={i}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right',
                    backgroundColor: '#fafafa'
                  }}
                >
                  {val.toFixed(2)}
                </td>
              );
            })}
          </tr>
        );
        // If not collapsed, recursively render the nested rows
        if (!collapsedGroups[row.id] && row.nestedRows && row.nestedRows.length > 0) {
          rendered = rendered.concat(
            renderRowsRecursively(
              row.nestedRows,
              indent + 1,
              row.nestedRows.filter((r) => r.rowType !== 'total')
            )
          );
        }

      } else if (row.rowType === 'account') {
        // Normal account row with monthly values
        const acct = allAccounts.find((a) => String(a.id) === String(row.id)) || {};
        rendered.push(
          <tr key={row.id}>
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 1,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                minWidth: '280px'
              }}
            >
              <span style={{ marginLeft: indentPx, whiteSpace: 'nowrap' }}>{row.name}</span>
            </td>
            {monthlyPeriods.map((period, i) => {
              const val = acct.allMonthlyValues?.[period.label] || 0.0;
              return (
                <td
                  key={i}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right'
                  }}
                >
                  {val.toFixed(2)}
                </td>
              );
            })}
          </tr>
        );

      } else if (row.rowType === 'total') {
        // "total" row sums up the entire group above it
        const totals = computeGroupTotals(groupRows);
        rendered.push(
          <tr key={row.id} style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 2,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                minWidth: '280px'
              }}
            >
              <span style={{ marginLeft: indentPx, whiteSpace: 'nowrap' }}>{row.label}</span>
            </td>
            {monthlyPeriods.map((period, i) => (
              <td
                key={i}
                style={{
                  border: '1px solid #e0e0e0',
                  padding: '8px 10px',
                  textAlign: 'right'
                }}
              >
                {totals[period.label].toFixed(2)}
              </td>
            ))}
          </tr>
        );

      } else if (row.rowType === 'formula') {
        let computed = {};
        const lowerLabel = row.label.trim().toLowerCase();
        if (lowerLabel === 'total liabilities and equity') {
          computed = computeTotalLETotals();
        }
        rendered.push(
          <tr key={row.id} style={{ fontWeight: 'bold', backgroundColor: '#e6f7ff' }}>
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 2,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                minWidth: '280px'
              }}
            >
              <span style={{ marginLeft: indentPx, whiteSpace: 'nowrap' }}>{row.label}</span>
            </td>
            {monthlyPeriods.map((_, i) => (
              <td
                key={i}
                style={{
                  border: '1px solid #e0e0e0',
                  padding: '8px 10px',
                  textAlign: 'right'
                }}
              >
                {computed[_.label] !== undefined ? computed[_.label].toFixed(2) : ''}
                </td>
            ))}
          </tr>
        );

      } else if (row.rowType === 'text') {
        // "text" row is italic and doesn't display monthly columns
        rendered.push(
          <tr key={row.id} style={{ fontStyle: 'italic', backgroundColor: '#fff' }}>
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 2,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                minWidth: '280px'
              }}
            >
              <span style={{ marginLeft: indentPx, whiteSpace: 'nowrap' }}>{row.label}</span>
            </td>
            {monthlyPeriods.map((_, i) => (
              <td
                key={i}
                style={{
                  border: '1px solid #e0e0e0',
                  padding: '8px 10px',
                  textAlign: 'right'
                }}
              ></td>
            ))}
          </tr>
        );
      }
      return rendered;
    });
  };

  // Handle loading and errors
  if (isLoading) return <p>Loading global data...</p>;
  if (error) return <p>{error}</p>;
  if (!organisation) return <p>Organisation not found.</p>;
  if (!integration) return <p>Xero Integration not found or inactive.</p>;

  // Define a common button style for a subtle, slightly darker blue design with black text
  const subtleBlueButtonStyle = {
    marginRight: '10px',
    padding: '8px 12px',
    border: 'none',
    backgroundColor: '#dbdbdb',   // slightly darker blue background
    color: '#000',                // black text
    cursor: 'pointer',
    borderRadius: '4px'
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', color: '#333' }}>
      <h2 style={{ marginBottom: '10px' }}>{organisation.name}</h2>
      <div style={{ marginBottom: '10px' }}>
        <Link to={`/organisation/${organisation.id}/activity-log`} style={{ marginRight: '15px', color: '#007bff', textDecoration: 'none' }}>
          Activity Log
        </Link>
        <Link to={`/organisation/${organisation.id}/start-subscription`} style={{ color: '#007bff', textDecoration: 'none' }}>Start Subscription</Link>
      </div>
      <p style={{ margin: '5px 0' }}>Created By: {organisation.createdBy}</p>
      <p style={{ margin: '5px 0' }}>Stripe ID: {organisation.stripeId}</p>
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <button
          style={subtleBlueButtonStyle}
          onClick={() => navigate(`/organisation/${organisation.id}`)}
        >
          Back to Organisation
        </button>
        <button
          style={subtleBlueButtonStyle}
          onClick={() =>
            navigate(`/organisation/${organisation.id}/xero/${integration.id}/edit-bs-layout`)
          }
        >
          Edit Balance Sheet Layout
        </button>
        <button
          style={{ ...subtleBlueButtonStyle, marginLeft: '10px' }}
          onClick={handleDisconnectXero}
        >
          Disconnect Xero
        </button>
      </div>

      {/* The P&L table with horizontal scrolling */}
      <div style={{ maxHeight: '600px', overflowY: 'auto', overflowX: 'auto' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ borderBottom: '2px solid #ccc', backgroundColor: '#f0f0f0' }}>
              <th
                style={{
                  border: '1px solid #e0e0e0',
                  padding: '8px 10px',
                  textAlign: 'left',
                  position: 'sticky',
                  top: 0,
                  left: '-2px',
                  backgroundColor: '#f0f0f0',
                  zIndex: 3,
                  minWidth: '300px'
                }}
              >
                Name
              </th>
              {monthlyPeriods.map((period) => (
                <th
                  key={period.label}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right',
                    position:'sticky',
                    top: 0,
                    backgroundColor: '#f0f0f0'
                  }}
                >
                  {period.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{renderRowsRecursively(bsRows)}</tbody>
        </table>
      </div>

      <div style={{ marginTop: '20px' }}>
        <h3 style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>QuickBooks</h3>
        <p>To be implemented...</p>
      </div>
    </div>
  );
};

export default XeroBalanceSheetPage;
