// src/Components/Xero/XeroCashJournalsPage.js

import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../authcontext';
import api from '../api'; // Assuming you have an API module

const XeroCashJournalsPage = () => {
  const { integrationId, orgId } = useParams();
  const navigate = useNavigate();
  const { organisations, xeroIntegrations, isLoading, isLoggedIn } = useAuth();

  // Local state
  const [organisation, setOrganisation] = useState(null);
  const [integration, setIntegration] = useState(null);
  const [cashJournals, setCashJournals] = useState([]);
  const [idToTypeMap, setIdToTypeMap] = useState({});
  const [error, setError] = useState(null);

  // Helper: Parse date from Xero's format
  const parseXeroDate = (xeroDateStr) => {
    try {
      const timestamp = parseInt(xeroDateStr.match(/\d+/)[0], 10);
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    } catch (e) {
      console.error('Error parsing date:', e);
      return 'Invalid Date';
    }
  };

  // Define transaction types with their corresponding array keys
  const transactionTypes = {
    bankTransactions: { type: 'Bank Transaction', arrayKey: 'BankTransactions' },
    bankTransfers: { type: 'Bank Transfer', arrayKey: 'BankTransfers' },
    creditNotes: { type: 'Credit Note', arrayKey: 'CreditNotes' },
    invoices: { type: 'Invoice', arrayKey: 'Invoices' },
    overpayments: { type: 'Overpayment', arrayKey: 'Overpayments' },
    payments: { type: 'Payment', arrayKey: 'Payments' },
    prepayments: { type: 'Prepayment', arrayKey: 'Prepayments' },
    purchaseOrders: { type: 'Purchase Order', arrayKey: 'PurchaseOrders' },
    expenseClaims: { type: 'Expense Claim', arrayKey: 'ExpenseClaims' }
  };

  // Fetch organisation and integration
  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      const orgIdInt = parseInt(orgId, 10);
      const foundOrg = organisations.find((org) => org.id === orgIdInt);

      if (foundOrg) {
        setOrganisation(foundOrg);
        const foundIntegration = xeroIntegrations[foundOrg.id]?.find(
          (xi) => String(xi.id) === String(integrationId) && xi.connectionStatus === 'active'
        );

        if (foundIntegration) {
          setIntegration(foundIntegration);
          // Parse cash journals
          try {
            const parsedCashJournals = JSON.parse(foundIntegration.cashJournals || '{"Journals": []}');
            setCashJournals(parsedCashJournals.Journals || []);
          } catch (err) {
            console.error('Error parsing cash journals JSON:', err);
            setCashJournals([]);
          }

          // Parse all transaction types and build ID to Type mapping
          try {
            const mapping = {};

            Object.keys(transactionTypes).forEach((key) => {
              const { type, arrayKey } = transactionTypes[key];
              const transactionsJSON = foundIntegration[key] || '[]';
              let transactions;

              try {
                const parsedData = JSON.parse(transactionsJSON);
                transactions = parsedData[arrayKey] || [];
              } catch (parseError) {
                console.error(`Error parsing JSON for ${key}:`, parseError);
                return; // Skip this transaction type if JSON is malformed
              }

              if (!Array.isArray(transactions)) {
                console.warn(`Expected ${arrayKey} to be an array, but received:`, transactions);
                return; // Skip if transactions is not an array
              }

              transactions.forEach((txn) => {
                // Determine the unique ID based on transaction type
                let txnID = '';
                switch (key) {
                  case 'bankTransactions':
                    txnID = txn.BankTransactionID;
                    break;
                  case 'bankTransfers':
                    txnID = txn.BankTransferID;
                    break;
                  case 'creditNotes':
                    txnID = txn.CreditNoteID;
                    break;
                  case 'invoices':
                    txnID = txn.InvoiceID;
                    break;
                  case 'overpayments':
                    txnID = txn.OverpaymentID;
                    break;
                  case 'payments':
                    txnID = txn.PaymentID;
                    break;
                  case 'prepayments':
                    txnID = txn.PrepaymentID;
                    break;
                  case 'purchaseOrders':
                    txnID = txn.PurchaseOrderID;
                    break;
                  case 'expenseClaims':  // **Handle Expense Claims**
                    txnID = txn.ExpenseClaimID;
                    break;
                  default:
                    break;
                }

                if (txnID) {
                  mapping[txnID] = type;
                } else {
                  console.warn(`Transaction in ${key} is missing its unique ID:`, txn);
                }
              });
            });

            setIdToTypeMap(mapping);
          } catch (err) {
            console.error('Unexpected error while parsing transaction types:', err);
            setIdToTypeMap({});
          }
        } else {
          setError('Xero Integration not found or inactive.');
        }
      } else {
        setError('Organisation not found.');
      }
    }
  }, [orgId, integrationId, organisations, xeroIntegrations, isLoading, isLoggedIn]);

  // Calculate totals
  const calculateTotals = (journal) => {
    let totalCredit = 0;
    let totalDebit = 0;

    journal.JournalLines.forEach((line) => {
      if (line.NetAmount > 0) {
        totalDebit += line.NetAmount;
      } else {
        totalCredit += Math.abs(line.NetAmount);
      }
    });

    return { totalCredit, totalDebit, lineCount: journal.JournalLines.length };
  };

  // Determine transaction type based on sourceID
  const getTransactionType = (SourceID) => {
    return idToTypeMap[SourceID] || 'None';
  };

  // Handle Disconnect
  const handleDisconnectXero = async () => {
    try {
      const response = await api.post('/xero/disconnect', {
        organisation_id: organisation.id,
        xero_integration_id: integration.id,
      });
      if (response.data.message) {
        navigate(`/organisation/${organisation.id}`);
        window.location.reload();
      }
    } catch (err) {
      console.error('Error disconnecting from Xero:', err);
      alert('Failed to disconnect from Xero');
    }
  };

  // Render Table
  const renderTable = () => (
    <table className="data-table">
      <thead>
        <tr>
          <th>Journal Date</th>
          <th>Journal Number</th>
          <th>Source Type</th>
          <th>Transaction Type</th> {/* New Column */}
          <th>Total Debit</th>
          <th>Total Credit</th>
          <th>Number of Rows</th>
        </tr>
      </thead>
      <tbody>
        {cashJournals.map((journal) => {
          const { totalCredit, totalDebit, lineCount } = calculateTotals(journal);
          const transactionType = getTransactionType(journal.SourceID); // Correct field name
          //console.log(`Journal ID: ${journal.JournalID}, SourceID: ${journal.SourceID}, Transaction Type: ${transactionType}`);

          return (
            <tr key={journal.JournalID}>
              <td>{parseXeroDate(journal.JournalDate)}</td>
              <td>
                <Link to={`/organisation/${orgId}/xero/${integrationId}/journals/cash/${journal.JournalID}`}>
                  {journal.JournalNumber}
                </Link>
              </td>
              <td>{journal.SourceType}</td>
              <td>{transactionType}</td> {/* Display Transaction Type */}
              <td>{totalDebit.toFixed(2)}</td>
              <td>{totalCredit.toFixed(2)}</td>
              <td>{lineCount}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  // Handle Loading and Errors
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!organisation) return <p>Organisation not found.</p>;
  if (!integration) return <p>Xero Integration not found or inactive.</p>;

  return (
    <div>
      <h2>Cash Journals for {organisation.name}</h2>
      <div>
        <button onClick={() => navigate(`/organisation/${orgId}/xero/${integrationId}/accrual-journals`)}>
          View Accrual Journals
        </button>
        <button onClick={handleDisconnectXero}>Disconnect Xero</button>
      </div>
      {cashJournals.length > 0 ? (
        renderTable()
      ) : (
        <p>No Cash Journals found for the selected period.</p>
      )}
      <Link to={`/organisation/${orgId}`}>Back to Organisation</Link>
    </div>
  );
};

export default XeroCashJournalsPage;
