import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../authcontext';
import api from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ConsolidatedProfitAndLossPage = () => {
  const { id, orgId } = useParams();
  const navigate = useNavigate();

  const {
    organisations,
    consolidations,
    xeroIntegrations,           // e.g. xeroIntegrations[orgId] => Array of integration objects
    isLoading,
    isLoggedIn
  } = useAuth();

  const [organisation, setOrganisation] = useState(null);
  const [consolidation, setConsolidation] = useState(null);
  const [error, setError] = useState(null);

  // The layout data from consolidation.plLayout
  const [companies, setCompanies] = useState([]);
  const [rows, setRows] = useState([]);


  // XeroIntegration formulas: xeroIntegrationFormulas["xero_1"] => { "Gross Profit": {...}, ... }
  const [xeroIntegrationFormulas, setXeroIntegrationFormulas] = useState({});

  // For subheading collapse
  const [collapsedGroups, setCollapsedGroups] = useState({});

  // Build monthly periods (24 months).
  const monthlyPeriods = useMemo(() => {
    const arr = [];
    let d = new Date();
    d.setDate(1);
    for (let i = 0; i < 24; i++) {
      const label = d.toLocaleString('default', { month: '2-digit', year: 'numeric' });
      arr.unshift(label);
      d.setMonth(d.getMonth() - 1);
    }
    return arr;
  }, []);

  // 1) Load organisation & consolidation
  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      const orgIdInt = parseInt(orgId, 10);
      const foundOrg = organisations.find((o) => o.id === orgIdInt);
      if (!foundOrg) {
        setError('Organisation not found in your data.');
        return;
      }
      setOrganisation(foundOrg);

      const foundCons = consolidations[foundOrg.id]?.find((c) => String(c.id) === String(id));
      if (!foundCons) {
        setError('Consolidation not found or inactive.');
        return;
      }
      setConsolidation(foundCons);
      setError(null);
    }
  }, [id, orgId, organisations, consolidations, isLoading, isLoggedIn]);

  // 2) Parse the consolidation layout once we have it
  useEffect(() => {
    if (!consolidation) return;
    try {
      const layoutObj = JSON.parse(consolidation.plLayout || '{}');
      if (Array.isArray(layoutObj.header?.companies)) {
        setCompanies(layoutObj.header.companies);  // e.g. [ { id:"xero_1", integrationId:1 }, ... ]
      }
      if (Array.isArray(layoutObj.rows)) {
        setRows(layoutObj.rows);
      }
    } catch (e) {
      console.error('Error parsing layout or formulas:', e);
    }
  }, [consolidation]);

  // 3) Once we have "companies" and "xeroIntegrations", build the real xeroIntegrationFormulas
  useEffect(() => {
    if (!companies || !xeroIntegrations) return;

    // We will build a temporary map: e.g. xeroIntegrationFormulas["xero_1"] = { "Gross Profit":..., "Net Profit":... }
    const formulasMap = {};

    companies.forEach((co) => {
      // Suppose each company object has co.id: "xero_1", and co.integrationId: 1
      const integrationId = co.integrationId; // If your layout actually stored it
      // Or if not, you might rely on a separate array in consolidation that says integrationId => "xero_1".

      // We look up the actual XeroIntegration object
      const integrationObj =
        xeroIntegrations[organisation.id]?.find((xi) => xi.id === integrationId);

      if (integrationObj) {
        try {
          // parse the integrationObj.formulas (a JSON string from DB)
          const parsed = JSON.parse(integrationObj.formulas || '{}');
          // Store it in the map under the "co.id"
          formulasMap[co.id] = parsed;
        } catch (err) {
          console.error('Error parsing XeroIntegration formulas for', co.id, err);
          formulasMap[co.id] = {};
        }
      } else {
        // if not found, store empty
        formulasMap[co.id] = {};
      }
    });

    setXeroIntegrationFormulas(formulasMap);
  }, [companies, xeroIntegrations, organisation]);

  function toggleCollapse(rowId) {
    setCollapsedGroups((prev) => ({
      ...prev,
      [rowId]: !prev[rowId]
    }));
  }

  async function handleDisconnectXero() {
    if (!organisation || !consolidation) return;
    try {
      await api.post('/xero/disconnect', {
        organisation_id: organisation.id,
        consolidation_id: consolidation.id
      });
      navigate(`/organisation/${organisation.id}`);
    } catch (err) {
      console.error('Error disconnecting:', err);
      alert('Failed to disconnect');
    }
  }

  // ------------------ Calculation helpers ------------------
  /**
   * computeRowValues(row):
   *   - Creates perCompany[companyId][month] => numeric
   *   - Creates totalAllCompanies[month] => numeric
   *   - For subheading/total rows, sums child rows recursively.
   *   - For formula rows (Gross Profit / Net Profit), look up each XeroIntegration’s data per company.
   */
  function computeRowValues(row) {
    const perCompany = {};
    companies.forEach((co) => {
      perCompany[co.id] = {};
      monthlyPeriods.forEach((m) => {
        perCompany[co.id][m] = 0;
      });
    });
    const totalAll = {};
    monthlyPeriods.forEach((m) => {
      totalAll[m] = 0;
    });

    if (row.rowType === 'accountMatch') {
      // Summation for each company's allMonthlyValues
      companies.forEach((co) => {
        const acct = row.accounts?.[co.id];
        if (acct && acct.allMonthlyValues) {
          monthlyPeriods.forEach((monthLabel) => {
            const val = Number(acct.allMonthlyValues[monthLabel] || 0);
            perCompany[co.id][monthLabel] = val;
            totalAll[monthLabel] += val;
          });
        }
      });
    }
    else if (
      row.rowType === 'subheading' ||
      row.rowType === 'subheading2' ||
      row.rowType === 'total'
    ) {
      // Sum the child rows
      if (Array.isArray(row.nestedRows) && row.nestedRows.length > 0) {
        row.nestedRows.forEach((child) => {
          const childVals = computeRowValues(child);
          // accumulate
          companies.forEach((co) => {
            monthlyPeriods.forEach((m) => {
              perCompany[co.id][m] += childVals.perCompany[co.id][m];
            });
          });
          monthlyPeriods.forEach((m) => {
            totalAll[m] += childVals.totalAllCompanies[m];
          });
        });
      }
    }
    else if (row.rowType === 'formula') {
      // e.g. "Gross Profit" or "Net Profit"
      const labelLow = (row.label || '').trim().toLowerCase();
      if (labelLow === 'gross profit' || labelLow === 'net profit') {
        // For each company, see if that company's integration has formula data for row.label
        companies.forEach((co) => {
          const coId = co.id; // e.g. "xero_1"
          const formulaByLabel = xeroIntegrationFormulas[coId]?.[row.label];
          if (formulaByLabel) {
            monthlyPeriods.forEach((m) => {
              const val = Number(formulaByLabel[m] || 0);
              // store in perCompany, and add to totalAll
              perCompany[coId][m] = val;
              totalAll[m] += val;
            });
          }
        });
      } 
      else {
        // Some other formula => fallback or use consolidation-level
      }
    }
    // rowType === 'text' => do nothing

    return {
      perCompany,
      totalAllCompanies: totalAll
    };
  }

  // -------------- Render table header --------------
  function renderTableHeader() {
    return (
      <thead>
        <tr style={{ backgroundColor: '#f0f0f0', borderBottom: '2px solid #ccc' }}>
          {/* Fixed "Name" column */}
          <th
            style={{
              position: 'sticky',
              top: 0,
              left: 0,
              zIndex: 3,
              backgroundColor: '#f0f0f0',
              border: '1px solid #e0e0e0',
              padding: '8px 10px',
              minWidth: '200px',
              textAlign: 'left'
            }}
          >
            Name
          </th>
          {/* For each month => group of cells */}
          {monthlyPeriods.map((m) => {
            // For each month group, we have several cells: one per company, then the elimination and total.
            // We add sticky positioning with top: 0 to each header cell.
            const coCols = companies.map((c, idx) => {
              const style = {
                position: 'sticky',
                top: 0,
                border: '1px solid #e0e0e0',
                textAlign: 'right',
                padding: '8px 10px',
                backgroundColor: '#f0f0f0'
              };
              if (idx === 0) {
                // Thicker left border for the beginning of the group
                style.borderLeft = '2px solid #666';
              }
              return (
                <th key={`${m}_${c.id}`} style={style}>
                  {c.name} {m}
                </th>
              );
            });
            const elimCol = (
              <th
                key={`${m}_elim`}
                style={{
                  position: 'sticky',
                  top: 0,
                  border: '1px solid #e0e0e0',
                  textAlign: 'right',
                  padding: '8px 10px',
                  backgroundColor: '#f0f0f0'
                }}
              >
                Elim {m}
              </th>
            );
            const totalCol = (
              <th
                key={`${m}_total`}
                style={{
                  position: 'sticky',
                  top: 0,
                  border: '1px solid #e0e0e0',
                  borderRight: '2px solid #666',
                  textAlign: 'right',
                  padding: '8px 10px',
                  backgroundColor: '#f0f0f0'
                }}
              >
                Total {m}
              </th>
            );
            return [...coCols, elimCol, totalCol];
          })}
        </tr>
      </thead>
    );
  }
  

  // -------------- Render table body --------------
  function renderRow(row, parentComputed = null) {
    const isSubheading = row.rowType === 'subheading' || row.rowType === 'subheading2';
    const isTotal = row.rowType === 'total';
    const isAccount = row.rowType === 'accountMatch';
    const isFormula = row.rowType === 'formula';
    const isText = row.rowType === 'text';

    // If total row has no children, fallback to parent's computed if available
    let computed;
    if (isTotal && (!row.nestedRows || row.nestedRows.length === 0) && parentComputed) {
      computed = parentComputed;
    } else {
      computed = computeRowValues(row);
    }

    const { perCompany, totalAllCompanies } = computed;

    // Row styling
    let rowStyle = {};
    if (isSubheading) {
      rowStyle = {
        fontWeight: 'bold',
        backgroundColor: row.rowType === 'subheading' ? '#f7f7f7' : '#fafafa',
        borderBottom: '1px solid #e0e0e0'
      };
    } else if (isTotal) {
      rowStyle = { fontWeight: 'bold', backgroundColor: '#f0f0f0' };
    } else if (isFormula) {
      rowStyle = { fontWeight: 'bold', backgroundColor: '#e6f7ff' };
    } else if (isText) {
      rowStyle = { fontStyle: 'italic' };
    }

    const collapsed = isSubheading && collapsedGroups[row.id];
    const hasChildren = Array.isArray(row.nestedRows) && row.nestedRows.length > 0;

    const mainRow = (
      <tr key={row.id} style={rowStyle}>
        {/* Single pinned "Name" column; using displayName if available */}
        <td
          style={{
            position: 'sticky',
            left: 0,
            zIndex: 2,
            backgroundColor: '#fff',
            border: '1px solid #e0e0e0',
            padding: '8px 10px',
            minWidth: '200px',
            whiteSpace: 'nowrap'
          }}
        >
          {isSubheading && (
            <span
              style={{ cursor: 'pointer', marginRight: 5 }}
              onClick={() => toggleCollapse(row.id)}
            >
              <FontAwesomeIcon
                icon={collapsedGroups[row.id] ? faChevronRight : faChevronDown}
                size="sm"
              />
            </span>
          )}
          {row.displayName || row.label || ''}
        </td>

        {/* For each month => co vals + elim + total */}
        {monthlyPeriods.flatMap((m) => {
          const coCols = companies.map((co, idx) => {
            const val = perCompany[co.id][m] || 0;
            const style = {
              border: '1px solid #e0e0e0',
              textAlign: 'right',
              padding: '8px 10px'
            };
            if (idx === 0) {
              style.borderLeft = '2px solid #666';
            }
            return (
              <td key={`${row.id}_${m}_${co.id}`} style={style}>
                {val.toFixed(2)}
              </td>
            );
          });
          // Blank elimination col
          const elimCol = (
            <td
              key={`${row.id}_${m}_elim`}
              style={{
                border: '1px solid #e0e0e0',
                textAlign: 'right',
                padding: '8px 10px'
              }}
            />
          );
          const sumVal = totalAllCompanies[m] || 0;
          const totalCol = (
            <td
              key={`${row.id}_${m}_total`}
              style={{
                border: '1px solid #e0e0e0',
                borderRight: '2px solid #666',
                textAlign: 'right',
                padding: '8px 10px'
              }}
            >
              {sumVal.toFixed(2)}
            </td>
          );
          return [...coCols, elimCol, totalCol];
        })}
      </tr>
    );

    let childElems = [];
    if (hasChildren && !collapsed) {
      for (const child of row.nestedRows) {
        childElems = [...childElems, ...renderRow(child, computed)];
      }
    }

    return [mainRow, ...childElems];
  }

  function renderTableBody() {
    return (
      <tbody>
        {rows.flatMap((r) => renderRow(r))}
      </tbody>
    );
  }

  // handle loading or error
  if (isLoading) return <p>Loading global data...</p>;
  if (error) return <p>{error}</p>;
  if (!organisation) return <p>Organisation not found.</p>;
  if (!consolidation) return <p>Consolidation not found or inactive.</p>;

  const subtleBlueButtonStyle = {
    marginRight: '10px',
    padding: '8px 12px',
    border: 'none',
    backgroundColor: '#dbdbdb',
    color: '#000',
    cursor: 'pointer',
    borderRadius: '4px'
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', color: '#333' }}>
      <h2 style={{ marginBottom: '10px' }}>{organisation.name}</h2>
      <div style={{ marginBottom: '10px' }}>
        <Link
          to={`/organisation/${organisation.id}/activity-log`}
          style={{ marginRight: '15px', color: '#007bff', textDecoration: 'none' }}
        >
          Activity Log
        </Link>
        <Link
          to={`/organisation/${organisation.id}/start-subscription`}
          style={{ color: '#007bff', textDecoration: 'none' }}
        >
          Start Subscription
        </Link>
      </div>
  
      <p style={{ margin: '5px 0' }}>Created By: {organisation.createdBy}</p>
      <p style={{ margin: '5px 0' }}>Stripe ID: {organisation.stripeId}</p>
  
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <button
          style={subtleBlueButtonStyle}
          onClick={() => navigate(`/organisation/${organisation.id}`)}
        >
          Back to Organisation
        </button>
        <button
          style={subtleBlueButtonStyle}
          onClick={() =>
            navigate(`/organisation/${organisation.id}/xero/${consolidation.id}/edit-pl-layout`)
          }
        >
          Edit P&L Layout
        </button>
        <button
          style={{ ...subtleBlueButtonStyle, marginLeft: '10px' }}
          onClick={handleDisconnectXero}
        >
          Disconnect Xero
        </button>
      </div>
  
      {/* Wrap the table in a container with a fixed height and vertical overflow enabled */}
      <div style={{ maxHeight: '600px', overflowY: 'auto', overflowX: 'auto' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          {renderTableHeader()}
          {renderTableBody()}
        </table>
      </div>
    </div>
  );  
};

export default ConsolidatedProfitAndLossPage;
