// XeroIndirectLayoutEditor.js
import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../authcontext';
import api from '../api';

import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

// Generate unique ID if needed.
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}


/**
 * Recursively searches through `rows` (and their nestedRows) for a row
 * whose `accountCode` matches `code`. Returns that row object if found.
 */
function findAccountRowRecursive(rows, code) {
  for (const r of rows) {
    if (r.accountCode === code) {
      return r;
    }
    if (r.nestedRows && r.nestedRows.length > 0) {
      const found = findAccountRowRecursive(r.nestedRows, code);
      if (found) return found;
    }
  }
  return null;
}

/**
 * Recursively updates `sign` for any row with `accountCode = code`.
 * This allows toggling sign on rows even if they are nested inside groups.
 */
function updateSignRecursive(rows, code, newSign) {
  for (const r of rows) {
    if (r.accountCode === code) {
      r.sign = newSign;
    } else if (r.nestedRows && r.nestedRows.length > 0) {
      updateSignRecursive(r.nestedRows, code, newSign);
    }
  }
}

/* ============================================================================
   MAIN DRAG–DROP SECTION (Net Profit and Operating Activities)
   This part is similar to our previous implementation and supports drag, 
   inline editing, group removals, and sign toggling.
============================================================================ */
function SortableRow({
  row,
  parentPath,
  collapsedBlocks,
  toggleCollapse,
  selectedRows,
  onCheckboxChange,
  onLabelUpdate,
  onRemoveGroup,
  onSignChange,
  editingRows,
  startEditing,
  stopEditing,
  renderNestedRows,
  operatingSectionId,
  style: externalStyle,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: row.id,
    data: { parentPath },
  });
  const sortableStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: '#fff',
    borderRadius: 4,
    marginBottom: 4,
    cursor: 'grab',
    ...externalStyle,
  };

  const renderLabel = () => {
    const displayText = row.label || '';
    if (editingRows[row.id]) {
      return (
        <input
          type="text"
          defaultValue={displayText}
          autoFocus
          onBlur={(e) => {
            stopEditing(row.id);
            onLabelUpdate(row.id, e.target.value, parentPath);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              stopEditing(row.id);
              onLabelUpdate(row.id, e.target.value, parentPath);
            }
          }}
          style={{
            fontSize: row.rowType === 'subheading' ? '16px' : '14px',
            flexGrow: 1,
            padding: 4,
            border: '1px solid #ccc',
            borderRadius: 4,
          }}
        />
      );
    }
    return (
      <span
        onDoubleClick={() => startEditing(row.id)}
        style={{ cursor: 'pointer', flexGrow: 1 }}
      >
        {displayText}
      </span>
    );
  };

  const renderRowContent = () => {
    switch (row.rowType) {
      case 'subheading':
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Chevron to toggle collapse */}
            <div
              style={{ cursor: 'pointer', marginRight: 8 }}
              onClick={(e) => {
                e.stopPropagation();
                toggleCollapse(row.id);
              }}
            >
              <FontAwesomeIcon
                icon={collapsedBlocks[row.id] ? faChevronRight : faChevronDown}
                size="sm"
              />
            </div>
            {renderLabel()}
            {parentPath.length > 0 && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    window.confirm(
                      'Remove this group? Its underlying rows will be merged at the same level.'
                    )
                  ) {
                    onRemoveGroup(row.id, parentPath);
                  }
                }}
                style={{
                  marginLeft: 8,
                  cursor: 'pointer',
                  color: '#777',
                  padding: '2px 6px',
                  border: '1px solid #777',
                  borderRadius: 4,
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} size="xs" />
              </div>
            )}
          </div>
        );
      case 'formula':
        return (
          <div onDoubleClick={() => startEditing(row.id)} style={{ fontStyle: 'italic' }}>
            {renderLabel()}
          </div>
        );
      case 'total':
        return (
          <div onDoubleClick={() => startEditing(row.id)} style={{ fontWeight: 'bold' }}>
            {renderLabel()}
          </div>
        );
      case 'account':
      default:
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row.rowType === 'account' && (
              <>
                <input
                  type="checkbox"
                  checked={!!selectedRows[row.id]}
                  onChange={(e) => {
                    e.stopPropagation();
                    onCheckboxChange(row.id, parentPath);
                  }}
                  style={{ marginRight: 8 }}
                />
                {/* Plus/Minus buttons for sign selection */}
                {parentPath.length > 0 && parentPath[0] === operatingSectionId && (
                  <>
                    <button
                      style={{
                        marginRight: 4,
                        backgroundColor: row.sign === '+' ? 'lightgreen' : '#eee',
                        border: '1px solid #999',
                        borderRadius: 4,
                        cursor: 'pointer',
                        width: 16,
                        height: 16,
                        padding: 0,
                        lineHeight: '16px',
                        fontSize: '12px',
                        textAlign: 'center',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onSignChange(row.accountCode, '+');
                      }}
                    >
                      +
                    </button>
                    <button
                      style={{
                        marginRight: 8,
                        backgroundColor: row.sign === '-' ? 'lightcoral' : '#eee',
                        border: '1px solid #999',
                        borderRadius: 4,
                        cursor: 'pointer',
                        width: 16,
                        height: 16,
                        padding: 0,
                        lineHeight: '16px',
                        fontSize: '12px',
                        textAlign: 'center',
                      }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSignChange(row.accountCode, '-');
                  }}
                >
                  -
                </button>
              </>
            )}
            </>
            )}
            {row.accountCode ? (
              <span>
                {row.accountCode} -{' '}
                {editingRows[row.id] ? (
                  <input
                    type="text"
                    defaultValue={row.name || ''}
                    autoFocus
                    onBlur={(e) => {
                      stopEditing(row.id);
                      onLabelUpdate(row.id, e.target.value, parentPath);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        stopEditing(row.id);
                        onLabelUpdate(row.id, e.target.value, parentPath);
                      }
                    }}
                    style={{
                      fontSize: '14px',
                      padding: 4,
                      border: '1px solid #ccc',
                      borderRadius: 4,
                    }}
                  />
                ) : (
                  <span onDoubleClick={() => startEditing(row.id)} style={{ cursor: 'pointer' }}>
                    {row.name || ''}
                  </span>
                )}
              </span>
            ) : (
              renderLabel()
            )}
          </div>
        );
    }
  };

  return (
    <div ref={setNodeRef} style={sortableStyle} {...attributes} {...listeners}>
      <div style={{ padding: 8, border: '1px solid #ccc', borderRadius: 4 }}>
        {renderRowContent()}
      </div>
      {row.rowType === 'subheading' && row.nestedRows && !collapsedBlocks[row.id] && (
        <div style={{ marginLeft: 25, marginTop: 4 }}>
          {renderNestedRows(row, parentPath)}
        </div>
      )}
    </div>
  );
}

/* ============================================================================
   NestedSortableContext (for main layout drag & drop)
============================================================================ */
function NestedSortableContext({
  parentBlock,
  parentPath,
  collapsedBlocks,
  toggleCollapse,
  selectedRows,
  onCheckboxChange,
  onLabelUpdate,
  onRemoveGroup,
  onSignChange,
  editingRows,
  startEditing,
  stopEditing,
  operatingSectionId,
}) {
  const draggableRows =
    parentBlock.nestedRows?.filter((r) => r.rowType !== 'total') || [];
  const totalRow = parentBlock.nestedRows?.find((r) => r.rowType === 'total');

  const renderNestedRows = (block, path) => (
      <NestedSortableContext
        parentBlock={block}
        parentPath={[...path, block.id]}
        collapsedBlocks={collapsedBlocks}
        toggleCollapse={toggleCollapse}
        selectedRows={selectedRows}
        onCheckboxChange={onCheckboxChange}
        onLabelUpdate={onLabelUpdate}
        onRemoveGroup={onRemoveGroup}
        onSignChange={onSignChange}
        editingRows={editingRows}
        startEditing={startEditing}
        stopEditing={stopEditing}
        operatingSectionId={operatingSectionId}
      />
    );

  return (
    <>
      <SortableContext items={draggableRows.map((r) => r.id)} strategy={verticalListSortingStrategy}>
        {draggableRows.map((childRow) => (
          <SortableRow
            key={childRow.id}
            row={childRow}
            parentPath={parentPath}
            collapsedBlocks={collapsedBlocks}
            toggleCollapse={toggleCollapse}
            selectedRows={selectedRows}
            onCheckboxChange={onCheckboxChange}
            onLabelUpdate={onLabelUpdate}
            onRemoveGroup={onRemoveGroup}
            onSignChange={onSignChange}
            editingRows={editingRows}
            startEditing={startEditing}
            stopEditing={stopEditing}
            renderNestedRows={renderNestedRows}
            operatingSectionId={operatingSectionId}
          />
        ))}
      </SortableContext>
      {totalRow && (
        <div
          style={{
            marginTop: 4,
            backgroundColor: '#f9f9f9',
            borderRadius: 4,
            padding: 6,
            border: '1px solid #ccc',
            fontWeight: 'bold',
          }}
        >
          {totalRow.label}
        </div>
      )}
    </>
  );
}

/* ============================================================================
   AVAILABLE ACCOUNTS PANEL (Non–draggable; used to include or remove new rows)
   This panel is collapsible with its own chevron. It groups all accounts by type
   and renders each account with a checkbox and plus/minus toggle.
============================================================================ */

function AvailableAccountsPanel({
  accountsMap,
  includedAccountCodes,
  onToggleInclude, // callback: (accountCode, include)
  onToggleSign,    // callback: (accountCode, sign)
  getSignForAccount, // callback: (accountCode) => current sign ('+' or '-')
}) {
  const [expandedGroups, setExpandedGroups] = useState({});

  // Group accounts by their typeGroup (defaulting to 'Others' if not provided)
  const groupedAccounts = useMemo(() => {
    const result = {};
    for (const acc of accountsMap.values()) {
      const group = acc.typeGroup || 'Others';
      if (!result[group]) {
        result[group] = [];
      }
      result[group].push(acc);
    }
    return result;
  }, [accountsMap]);

  // Use "available" as the key for the entire panel collapse state.
  const togglePanel = () => {
    setExpandedGroups(prev => ({ ...prev, available: !prev.available }));
  };

  const toggleGroup = (group) => {
    setExpandedGroups(prev => ({ ...prev, [group]: !prev[group] }));
  };

  return (
    <div style={{
      border: '1px solid #ccc',
      borderRadius: 4,
      padding: 8,
      marginBottom: 20,
      backgroundColor: '#f5f5f5'
    }}>
      <h4 style={{ margin: 0, cursor: 'pointer' }} onClick={togglePanel}>
        {expandedGroups.available ? (
          <FontAwesomeIcon icon={faChevronDown} size="sm" style={{ marginRight: 8 }} />
        ) : (
          <FontAwesomeIcon icon={faChevronRight} size="sm" style={{ marginRight: 8 }} />
        )}
        Add/Remove rows from the Operating Activities section
      </h4>
      {expandedGroups.available && (
        <div style={{ marginTop: 8 }}>
          {Object.entries(groupedAccounts).map(([group, accounts]) => (
            <div key={group} style={{
              marginBottom: 8,
              borderTop: '1px solid #ddd',
              paddingTop: 4
            }}>
              <div
                style={{ fontWeight: 'bold', cursor: 'pointer' }}
                onClick={() => toggleGroup(group)}
              >
                {expandedGroups[group] ? (
                  <FontAwesomeIcon icon={faChevronDown} size="sm" style={{ marginRight: 4 }} />
                ) : (
                  <FontAwesomeIcon icon={faChevronRight} size="sm" style={{ marginRight: 4 }} />
                )}
                {group}
              </div>
              {expandedGroups[group] &&
                accounts.map((acc) => {
                  const included = includedAccountCodes.includes(acc.accountCode);
                  // Use the provided getSignForAccount to obtain the current sign:
                  const currentSign = getSignForAccount(acc.accountCode) || '+';
                  return (
                    <div key={acc.accountCode} style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 16,
                      marginTop: 4
                    }}>
                      {/* Checkbox */}
                      <input
                        type="checkbox"
                        checked={included}
                        onChange={(e) => onToggleInclude(acc.accountCode, e.target.checked)}
                        style={{ marginRight: 4, width: 16, height: 16 }}
                      />
                      {/* Plus/Minus buttons for sign selection */}
                      {included && (
                        <>
                          <button
                            style={{
                              marginRight: 4,
                              width: 16,
                              height: 16,
                              backgroundColor: currentSign === '+' ? 'lightgreen' : '#eee',
                              border: '1px solid #999',
                              borderRadius: 2,
                              cursor: 'pointer',
                              padding: 0,
                              lineHeight: '16px',
                              fontSize: '12px',
                              textAlign: 'center'
                            }}
                            onClick={() => onToggleSign(acc.accountCode, '+')}
                          >
                            +
                          </button>
                          <button
                            style={{
                              marginRight: 4,
                              width: 16,
                              height: 16,
                              backgroundColor: currentSign === '-' ? 'lightcoral' : '#eee',
                              border: '1px solid #999',
                              borderRadius: 2,
                              cursor: 'pointer',
                              padding: 0,
                              lineHeight: '16px',
                              fontSize: '12px',
                              textAlign: 'center'
                            }}
                            onClick={() => onToggleSign(acc.accountCode, '-')}
                          >
                            &minus;
                          </button>
                        </>
                      )}
                      <span style={{ flexGrow: 1 }}>
                        {acc.accountCode} - {acc.name}
                      </span>
                    </div>
                  );
                })}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}


/* ============================================================================
   MAIN COMPONENT: XeroIndirectLayoutEditor
   For this editor version, we load only the “Net Profit” and “Operating Activities”
   rows in the main layout; the available accounts panel (above) lets the user add or
   remove individual account rows (with a sign toggle) from the operating section.
============================================================================ */
export default function XeroIndirectLayoutEditor() {
  const navigate = useNavigate();
  const { orgId, integrationId } = useParams();
  const { organisations, xeroIntegrations } = useAuth();

  const [organisation, setOrganisation] = useState(null);
  const [integration, setIntegration] = useState(null);
  // Main layout blocks — only two rows initially:
  // 1. Net Profit formula row
  // 2. Operating Activities subheading row (with nestedRows that can be modified)
  const [blocks, setBlocks] = useState([]);
  // We'll keep a map of accounts (keyed by accountCode) for the available accounts panel.
  const [accountsMap, setAccountsMap] = useState(new Map());
  const [error, setError] = useState(null);
  // DnD collapse/checkbox/editing states (for main layout)
  const [collapsedBlocks, setCollapsedBlocks] = useState({});
  const [selectedRows, setSelectedRows] = useState({});
  const [groupCounter, setGroupCounter] = useState(1);
  const [editingRows, setEditingRows] = useState({});

  // Also, maintain a list (array) of available account codes that are already included in the main layout.
  const [includedAccountCodes, setIncludedAccountCodes] = useState([]);

  // On load, get organisation and integration details.
  useEffect(() => {
    if (!organisations || !xeroIntegrations) return;
    const foundOrg = organisations.find((o) => String(o.id) === String(orgId));
    if (!foundOrg) {
      setError('Organisation not found.');
      return;
    }
    setOrganisation(foundOrg);

    const foundIntegration = xeroIntegrations[foundOrg.id]?.find(
      (xi) => String(xi.id) === String(integrationId)
    );
    if (!foundIntegration) {
      setError('Xero Integration not found.');
      return;
    }
    setIntegration(foundIntegration);
  }, [orgId, integrationId, organisations, xeroIntegrations]);

  // Parse the full layout and accounts.
  useEffect(() => {
    if (!integration) return;
    // Parse the complete layout from integration.cfIndirectLayout.
    try {
      const data = JSON.parse(integration.cfIndirectLayout || '[]');
      setBlocks(Array.isArray(data) ? data : []);
    } catch (err) {
      console.error('Error parsing layout data =>', err);
      setBlocks([]);
    }
    // Parse accounts from integration.accounts.
    try {
      let parsed = [];
      if (typeof integration.accounts === 'string') {
        const temp = JSON.parse(integration.accounts);
        parsed = Array.isArray(temp.accounts) ? temp.accounts : [];
      } else if (Array.isArray(integration.accounts?.accounts)) {
        parsed = integration.accounts.accounts;
      } else if (Array.isArray(integration.accounts)) {
        parsed = integration.accounts;
      }
      const m = new Map();
      parsed.forEach((acc) => {
        if (acc.accountCode) {
          m.set(acc.accountCode, acc);
        }
      });
      setAccountsMap(m);
    } catch (err) {
      console.error('Error parsing accounts =>', err);
      setAccountsMap(new Map());
    }
    const opSection = blocks.find(
      (b) => b.rowType === 'subheading' && b.label.trim().toLowerCase() === 'operating activities'
    );
    if (opSection && Array.isArray(opSection.nestedRows)) {
      // Gather accountCodes from all nested rows, not just top-level
      const codes = [];
      function collectCodesRecursive(rows) {
        for (const r of rows) {
          if (r.rowType === 'account' && r.accountCode) {
            codes.push(r.accountCode);
          } else if (r.nestedRows && r.nestedRows.length > 0) {
            collectCodesRecursive(r.nestedRows);
          }
        }
      }
      collectCodesRecursive(opSection.nestedRows);
        setIncludedAccountCodes(codes);
      } else {
        // If no operating section found, or no nestedRows
        setIncludedAccountCodes([]);
      }
  }, [integration]);

  useEffect(() => {
    const opSection = blocks.find(
      (b) =>
        b.rowType === 'subheading' &&
        b.label.trim().toLowerCase() === 'operating activities'
    );
    if (opSection && Array.isArray(opSection.nestedRows)) {
      // Collect account codes recursively from the operating section
      const codes = [];
      function collectCodesRecursive(rows) {
        for (const r of rows) {
          if (r.rowType === 'account' && r.accountCode) {
            codes.push(r.accountCode);
          } else if (r.nestedRows && r.nestedRows.length > 0) {
            collectCodesRecursive(r.nestedRows);
          }
        }
      }
      collectCodesRecursive(opSection.nestedRows);
      setIncludedAccountCodes(codes);
    } else {
      setIncludedAccountCodes([]);
    }
  }, [blocks]);
  

  // Compute the operating section id (used to decide whether to show toggles).
  const operatingSectionId = useMemo(() => {
    return blocks.find(
      (b) =>
        b.rowType === 'subheading' &&
        b.label.trim().toLowerCase() === 'operating activities'
    )?.id;
  }, [blocks]);
    const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const activeContainer = active.data?.current?.parentPath || [];
    const overContainer = over.data?.current?.parentPath || [];

    if (JSON.stringify(activeContainer) !== JSON.stringify(overContainer)) {
      const newState = structuredClone(blocks);
      function findBlockByPath(arr, path) {
        if (!path.length) return null;
        const [currentId, ...rest] = path;
        const sub = arr.find((b) => b.id === currentId);
        if (!sub) return null;
        if (!rest.length) return sub;
        if (!sub.nestedRows) return null;
        return findBlockByPath(sub.nestedRows, rest);
      }
      const sourceSub = findBlockByPath(newState, activeContainer);
      const destSub = findBlockByPath(newState, overContainer);
      if (!sourceSub || !destSub) return;
      const sourceRows = sourceSub.nestedRows?.filter((r) => r.rowType !== 'total') || [];
      const destRows = destSub.nestedRows?.filter((r) => r.rowType !== 'total') || [];
      const srcIndex = sourceRows.findIndex((r) => r.id === active.id);
      const destIndex = destRows.findIndex((r) => r.id === over.id);
      if (srcIndex === -1) return;
      const [moved] = sourceRows.splice(srcIndex, 1);
      const insertIndex = destIndex === -1 ? destRows.length : destIndex;
      destRows.splice(insertIndex, 0, moved);

      const sTotal = sourceSub.nestedRows?.find((r) => r.rowType === 'total');
      if (sTotal) {
        sourceSub.nestedRows = [...sourceRows, sTotal];
      } else {
        sourceSub.nestedRows = sourceRows;
      }
      const dTotal = destSub.nestedRows?.find((r) => r.rowType === 'total');
      if (dTotal) {
        destSub.nestedRows = [...destRows, dTotal];
      } else {
        destSub.nestedRows = destRows;
      }
      
      setBlocks(newState);
    } else {
      const newState = structuredClone(blocks);
      function findBlockByPath(arr, path) {
        if (!path.length) return null;
        const [currentId, ...rest] = path;
        const sub = arr.find((b) => b.id === currentId);
        if (!sub) return null;
        if (!rest.length) return sub;
        if (!sub.nestedRows) return null;
        return findBlockByPath(sub.nestedRows, rest);
      }
      if (!activeContainer.length) {
        const fromIndex = newState.findIndex((b) => b.id === active.id);
        const toIndex = newState.findIndex((b) => b.id === over.id);
        setBlocks(arrayMove(newState, fromIndex, toIndex));
        return;
      }
      const sub = findBlockByPath(newState, activeContainer);
      if (!sub) return;
      const subRows = sub.nestedRows?.filter((r) => r.rowType !== 'total') || [];
      const fromIndex = subRows.findIndex((r) => r.id === active.id);
      const toIndex = subRows.findIndex((r) => r.id === over.id);
      if (fromIndex === -1 || toIndex === -1) return;
      const re = arrayMove(subRows, fromIndex, toIndex);
      const totalRow = sub.nestedRows?.find((r) => r.rowType === 'total');
      sub.nestedRows = totalRow ? [...re, totalRow] : re;
      setBlocks(newState);
    }
  };

  const updateRowLabel = (rowId, newLabel, parentPath) => {
    const updateRecursive = (arr, path) => {
      for (let i = 0; i < arr.length; i++) {
        const row = arr[i];
        if (row.id === rowId) {
          if (row.rowType === 'account') {
            row.name = newLabel;
          } else {
            row.label = newLabel;
          }
          return true;
        }
        if (row.nestedRows && row.nestedRows.length) {
          if (updateRecursive(row.nestedRows, path.concat(row.id))) return true;
        }
      }
      return false;
    };
    const newState = structuredClone(blocks);
    updateRecursive(newState, []);
    setBlocks(newState);
  };

  const removeGroup = (groupId, parentPath) => {
    const removeRecursive = (arr, path) => {
      for (let i = 0; i < arr.length; i++) {
        const row = arr[i];
        if (row.id === groupId && row.rowType === 'subheading') {
          const underlying = row.nestedRows || [];
          arr.splice(i, 1, ...underlying);
          return true;
        }
        if (row.nestedRows && row.nestedRows.length) {
          if (removeRecursive(row.nestedRows, path.concat(row.id))) return true;
        }
      }
      return false;
    };
    const newState = structuredClone(blocks);
    removeRecursive(newState, []);
    setBlocks(newState);
  };

    // handle save
    const handleSave = async () => {
        if (!integration) return;
        const newLayout = blocks;
        const payload = {
          integrationId: integration.id,
          cfIndirectLayout: newLayout,
        };
    
        let endpoint = '/xero/update-cf-indirect-layout';
    
        try {
          const resp = await api.post(endpoint, payload, {
            headers: { 'Content-Type': 'application/json' },
          });
          if (resp.status === 200) {
            alert(
              "Indirect Cash Flow layout saved successfully!"
            );
            let path = `/organisation/${orgId}/xerocfindirect/${integrationId}`;
            navigate(path);
            window.location.reload();
          }
        } catch (err) {
          if (err.response) {
            console.error('Backend error:', err.response.data);
            alert(`Failed to save layout: ${err.response.data.error || 'Unknown error'}`);
          } else if (err.request) {
            console.error('No response from server:', err.request);
            alert('Failed to save layout: No response from server.');
          } else {
            console.error('Error setting up request:', err.message);
            alert(`Failed to save layout: ${err.message}`);
          }
        }
      };

  // When a row is toggled (checkbox in the top panel), update the main layout accordingly.
  function onToggleInclude(accountCode, include) {
    const newState = structuredClone(blocks);
    const operatingSection = newState.find(
      (b) => b.rowType === 'subheading' && b.label.trim().toLowerCase() === 'operating activities'
    );
    if (!operatingSection) return;

    if (!operatingSection.nestedRows) operatingSection.nestedRows = [];

    if (include) {
      // If not already included, push
      const existing = findAccountRowRecursive(operatingSection.nestedRows, accountCode);
      if (!existing) {
        operatingSection.nestedRows.push({
          rowType: 'account',
          accountCode,
          name: accountsMap.get(accountCode)?.name || '',
          id: generateUUID(),
          sign: '+',
        });
      }
      setIncludedAccountCodes((prev) =>
        prev.includes(accountCode) ? prev : [...prev, accountCode]
      );
    } else {
      // remove from nested
      function removeRecursive(rows) {
        for (let i = rows.length - 1; i >= 0; i--) {
          const r = rows[i];
          if (r.accountCode === accountCode) {
            rows.splice(i, 1);
          } else if (r.nestedRows && r.nestedRows.length) {
            removeRecursive(r.nestedRows);
          }
        }
      }
      removeRecursive(operatingSection.nestedRows);
      setIncludedAccountCodes((prev) => prev.filter((code) => code !== accountCode));
    }

    setBlocks(newState);
  }
  

  // onToggleSign: Update the sign for the account row in Operating Activities.
  function onToggleSign(accountCode, newSign) {
    // Make a copy of blocks so we can mutate
    const newState = structuredClone(blocks);
    
    // Find the top-level Operating Activities subheading
    const operatingSection = newState.find(
      (b) => b.rowType === 'subheading' && b.label.trim().toLowerCase() === 'operating activities'
    );
  
    if (!operatingSection || !operatingSection.nestedRows) {
      setBlocks(newState);
      return;
    }
  
    // A helper to recursively descend into nestedRows
    function updateSignRecursive(rows) {
      rows.forEach((r) => {
        // If the row's accountCode matches, update the sign
        if (r.accountCode === accountCode) {
          r.sign = newSign;
        }
        // Otherwise, if there's another level of nesting, keep descending
        else if (r.nestedRows && r.nestedRows.length > 0) {
          updateSignRecursive(r.nestedRows);
        }
      });
    }
  
    // Kick off the recursive update
    updateSignRecursive(operatingSection.nestedRows);
  
    // Finally, update the main layout state
    setBlocks(newState);
  }
  

  // Helper: Get the current sign for an account in Operating Activities.
  const getSignForAccount = (accountCode) => {
    const operatingSection = blocks.find(
      (b) => b.rowType === 'subheading' && b.label.trim().toLowerCase() === 'operating activities'
    );
    if (!operatingSection || !operatingSection.nestedRows) return '+';
    const row = findAccountRowRecursive(operatingSection.nestedRows, accountCode);
    return row && row.sign ? row.sign : '+';
  };
  
  // DnD sensors for the main layout.
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );

  const toggleCollapse = (id) => {
    setCollapsedBlocks((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleCheckboxChange = (rowId, parentPath) => {
    // (For the main layout, if needed; in this editor most checkbox toggling is handled in the top panel.)
    setSelectedRows((prev) => {
      const copy = { ...prev };
      if (copy[rowId]) {
        delete copy[rowId];
      } else {
        copy[rowId] = parentPath;
      }
      return copy;
    });
  };

  const startEditing = (rowId) => {
    setEditingRows((prev) => ({ ...prev, [rowId]: true }));
  };
  const stopEditing = (rowId) => {
    setEditingRows((prev) => {
      const copy = { ...prev };
      delete copy[rowId];
      return copy;
    });
  };

  // (The grouping functionality for checkboxes remains unchanged.)
  const canCreateNewGroup = useMemo(() => {
    const ids = Object.keys(selectedRows);
    if (ids.length < 2) return false;
    const paths = Object.values(selectedRows);
    const first = JSON.stringify(paths[0]);
    return paths.every((p) => JSON.stringify(p) === first);
  }, [selectedRows]);

  const handleCreateNewGroup = () => {
    const ids = Object.keys(selectedRows);
    if (ids.length < 2) return;
    const commonPath = selectedRows[ids[0]];
    if (!commonPath || !commonPath.length) {
      alert('Invalid parent path for new group. Are all rows in the same subheading?');
      return;
    }
    const subheadingId = commonPath[commonPath.length - 1];
    const newState = structuredClone(blocks);
    function groupRows(arr, targetId) {
      for (let i = 0; i < arr.length; i++) {
        const blk = arr[i];
        if (!blk || !blk.id) continue;
        if (blk.id === targetId) {
          const nestedRows = blk.nestedRows || [];
          let totalItem = null;
          const totalIndex = nestedRows.findIndex((x) => x.rowType === 'total');
          if (totalIndex !== -1) {
            totalItem = nestedRows[totalIndex];
          }
          const indices = nestedRows
            .map((r, index) => (ids.includes(r.id) ? index : null))
            .filter((ix) => ix !== null);
          if (!indices.length) return;
          const minIndex = Math.min(...indices);
          const toMove = nestedRows.filter((r) => ids.includes(r.id));
          if (toMove.length < 2) {
            alert('Select at least two rows in the same subheading.');
            return;
          }
          const uniqueGroupName = `New Group ${groupCounter}`;
          setGroupCounter((prev) => prev + 1);
          const newGroupRow = {
            rowType: 'subheading',
            label: uniqueGroupName,
            id: generateUUID(),
            nestedRows: toMove,
          };
          const remain = nestedRows.filter((r) => !ids.includes(r.id));
          remain.splice(minIndex, 0, newGroupRow);
          if (totalItem) {
            const withoutTotal = remain.filter((r) => r.rowType !== 'total');
            remain.length = 0;
            remain.push(...withoutTotal, totalItem);
          }
          blk.nestedRows = remain;
          return;
        }
        if (blk.nestedRows?.length) {
          groupRows(blk.nestedRows, targetId);
        }
      }
    }
    groupRows(newState, subheadingId);
    setBlocks(newState);
    setSelectedRows({});
    alert('New group created at the position of the top-most checked row!');
  };

  const handleExpandAll = () => {
    const expandState = (blocksArray) => {
      let result = {};
      blocksArray.forEach((block) => {
        if (block.rowType === 'subheading') {
          result[block.id] = false;
          if (block.nestedRows && block.nestedRows.length > 0) {
            result = { ...result, ...expandState(block.nestedRows) };
          }
        }
      });
      return result;
    };
    setCollapsedBlocks(expandState(blocks));
  };

  const handleCollapseAll = () => {
    const collapseState = (blocksArray) => {
      let result = {};
      blocksArray.forEach((block) => {
        if (block.rowType === 'subheading') {
          result[block.id] = true;
          if (block.nestedRows && block.nestedRows.length > 0) {
            result = { ...result, ...collapseState(block.nestedRows) };
          }
        }
      });
      return result;
    };
    setCollapsedBlocks(collapseState(blocks));
  };

  return (
    <div style={{ padding: 20 }}>
      <h3>Edit Indirect Cash Flow Layout for {organisation?.name}</h3>
      <p style={{ marginBottom: 10, fontStyle: 'italic' }}>
        Please <strong>Collapse All</strong> before reordering main sections. If you want to move a group, collapse it first.
      </p>
      <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center' }}>
        <button onClick={handleExpandAll} style={{ marginRight: 10 }}>Expand All</button>
        <button onClick={handleCollapseAll} style={{ marginRight: 10 }}>Collapse All</button>
      </div>
      {/* New top panel: Include New Rows (available accounts) */}
      <AvailableAccountsPanel
        accountsMap={accountsMap}
        includedAccountCodes={includedAccountCodes}
        onToggleInclude={onToggleInclude}
        onToggleSign={onToggleSign}
        getSignForAccount={getSignForAccount}
      />
      <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center' }}>
        <button onClick={handleSave} style={{ marginRight: 10 }}>Save Changes</button>
        {canCreateNewGroup && (
          <button onClick={handleCreateNewGroup} style={{ marginRight: 10 }}>New Group</button>
        )}
        <button onClick={() => navigate(-1)}>Back</button>
      </div>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={blocks.map((b) => b.id)} strategy={verticalListSortingStrategy}>
          {blocks.map((block) => (
            <SortableRow
              key={block.id}
              row={block}
              parentPath={[]}
              collapsedBlocks={collapsedBlocks}
              toggleCollapse={toggleCollapse}
              selectedRows={selectedRows}
              onCheckboxChange={handleCheckboxChange}
              onLabelUpdate={updateRowLabel}
              onRemoveGroup={removeGroup}
              onSignChange={onToggleSign}
              editingRows={editingRows}
              startEditing={startEditing}
              stopEditing={stopEditing}
              renderNestedRows={(b, path) => (
                <NestedSortableContext
                  parentBlock={b}
                  parentPath={[...path, b.id]}
                  collapsedBlocks={collapsedBlocks}
                  toggleCollapse={toggleCollapse}
                  selectedRows={selectedRows}
                  onCheckboxChange={handleCheckboxChange}
                  onLabelUpdate={updateRowLabel}
                  onRemoveGroup={removeGroup}
                  onSignChange={onToggleSign}
                  editingRows={editingRows}
                  startEditing={startEditing}
                  stopEditing={stopEditing}
                  operatingSectionId={operatingSectionId}
                />
              )}
              operatingSectionId={operatingSectionId}
            />
          ))}
        </SortableContext>
      </DndContext>
      <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
        <button onClick={handleSave} style={{ marginRight: 10 }}>Save Changes</button>
        {canCreateNewGroup && (
          <button onClick={handleCreateNewGroup} style={{ marginRight: 10 }}>New Group</button>
        )}
        <button onClick={() => navigate(-1)}>Back</button>
      </div>
    </div>
  );
}
