import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api';  // Ensure you have an API utility for making HTTP requests

const XeroFetchStatus = () => {
    const { orgId, xeroIntegrationId } = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState('Initiating fetch...');
    const [error, setError] = useState(null);

    useEffect(() => {
        const initiateFetch = async () => {
            try {
                const response = await api.post('/xero/start_fetch', {
                    org_id: parseInt(orgId, 10),
                    xero_integration_id: parseInt(xeroIntegrationId, 10),
                });
                if (response.data.message) {
                    setStatus(response.data.message);
                    // Optionally, redirect to a status page or dashboard
                    navigate(`/organisation/${orgId}/xero-integration/${xeroIntegrationId}/status`);
                }
            } catch (err) {
                console.error("Error initiating fetch:", err);
                setError("Failed to initiate fetching journals.");
            }
        };

        initiateFetch();
    }, [orgId, xeroIntegrationId, navigate]);

    return (
        <div>
            <h2>Fetching Journals</h2>
            {error ? <p style={{ color: 'red' }}>{error}</p> : <p>{status}</p>}
            {/* Optionally, add a loading spinner or progress bar */}
        </div>
    );
};

export default XeroFetchStatus;
