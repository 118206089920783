import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAuth, AuthProvider } from './Components/authcontext';
import './App.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';

import ScrollToTop from './Components/scrolltop';
import Navigation from './Components/nav';

import Homepage from './Components/homepage';
import MainSidebar from './Components/mainsidebar';

import LoginPage from './Components/Login/login';
import Registration from './Components/Login/registration';
import PasswordReset from './Components/Login/passwordreset';
import VerifyEmail from './Components/Login/verifyemail';
import RequestReset from './Components/Login/requestreset';

import MyAccount from './Components/myaccount';
import ManageOrganisations from './Components/manageorganisations';
import OrganisationPage from './Components/organisationpage';
import ActivityLogPage from './Components/activitylogpage';

import XeroProfitAndLossPage from './Components/Xero/xeropandlpage';
import XeroBalanceSheetPage from './Components/Xero/xerobspage';
import XeroDirectCashFlowPage from './Components/Xero/xerocfdirectpage';
import XeroIndirectCashFlowPage from './Components/Xero/xerocfindirectpage';

import ConsolidatedAccountMatchesPage from './Components/Xero/consolidatedaccountmatchespage';
import ConsolidatedProfitAndLossPage from './Components/Xero/consolidatedpandlpage';

import XeroLayoutEditor from './Components/Xero/xerolayouteditor';
import XeroIndirectLayoutEditor from './Components/Xero/xeroindirectlayouteditor';

import XeroCashJournalsPage from './Components/Xero/xerocashjournalspage';
import XeroAccrualJournalsPage from './Components/Xero/xeroaccrualjournalspage';
import XeroJournalDetailsPage from './Components/Xero/xerojournaldetailspage';
import XeroFetchStatus from './Components/Xero/xerofetchstatus';

import EmbeddedCheckoutPage from './Components/paymentpage';

function App() {
  const Layout = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isLoggedIn, isLoading } = useAuth();

    // Public paths where no sidebar is needed
    const publicPaths = ['/', '/register', '/verifyemail', '/login', '/requestreset', '/passwordreset'];
    
    // Paths that should show the Data sidebar
    const nonSidebarPaths = ['/organisation/:orgId/start-subscription'];

    useEffect(() => {
      if (isLoading) {
        return;
      }

      if (!isLoggedIn && !publicPaths.includes(location.pathname)) {
        navigate('/');
      } else if (isLoggedIn && publicPaths.includes(location.pathname)) {
        navigate('/home', { replace: true });
      }
    }, [isLoggedIn, isLoading, location.pathname, navigate]);

    const isNonSidebarRoute = nonSidebarPaths.some(path => location.pathname.startsWith(path));

    return (
      <>
        {/* Navigation is displayed on all non-public pages */}
        {!publicPaths.includes(location.pathname) && <Navigation />}
        
        {!isNonSidebarRoute && !publicPaths.includes(location.pathname) && <MainSidebar />}

        {/* Main content area */}
        <div className={!publicPaths.includes(location.pathname) ? `content ${!isNonSidebarRoute ? 'shifted' : ''}` : 'content login'}>
          {children}
        </div>
      </>
    );
  };

  return (
    <AuthProvider>
      <div className="App">
        <Router>
          <ScrollToTop />
          <div className="homecontainer">
            <Routes>
              <Route path="/" element={<Layout><LoginPage /></Layout>} />
              <Route path="/register" element={<Layout><Registration /></Layout>} />
              <Route path="/verifyemail" element={<Layout><VerifyEmail /></Layout>} />
              <Route path="/requestreset" element={<Layout><RequestReset /></Layout>} />
              <Route path="/passwordreset" element={<Layout><PasswordReset /></Layout>} />

              <Route path="/home" element={<Layout><Homepage /></Layout>} />
              <Route path="/account" element={<Layout><MyAccount /></Layout>} />
              <Route path="/organisations" element={<Layout><ManageOrganisations /></Layout>} />
              <Route path="/organisation/:orgId" element={<Layout><OrganisationPage /></Layout>} />
              <Route path="/organisation/:orgId/activity-log" element={<Layout><ActivityLogPage /></Layout>} />

              <Route path="/organisation/:orgId/xeropandl/:id" element={<Layout><XeroProfitAndLossPage /></Layout>} />
              <Route path="/organisation/:orgId/xerobs/:id" element={<Layout><XeroBalanceSheetPage /></Layout>} />
              <Route path="/organisation/:orgId/xerocfdirect/:id" element={<Layout><XeroDirectCashFlowPage /></Layout>} />
              <Route path="/organisation/:orgId/xerocfindirect/:id" element={<Layout><XeroIndirectCashFlowPage /></Layout>} />

              <Route path="/organisation/:orgId/consolidatedpandl/:id" element={<Layout><ConsolidatedProfitAndLossPage /></Layout>} />
              <Route path="/organisation/:orgId/consolidatedaccounts/:id" element={<Layout><ConsolidatedAccountMatchesPage /></Layout>} />

              <Route path="/organisation/:orgId/xero/:integrationId/edit-pl-layout" element={<Layout><XeroLayoutEditor /></Layout>} />
              <Route path="/organisation/:orgId/xero/:integrationId/edit-bs-layout" element={<Layout><XeroLayoutEditor /></Layout>} />
              <Route path="/organisation/:orgId/xero/:integrationId/edit-cf-direct-layout" element={<Layout><XeroLayoutEditor /></Layout>} />
              <Route path="/organisation/:orgId/xero/:integrationId/edit-cf-indirect-layout" element={<Layout><XeroIndirectLayoutEditor /></Layout>} />

              <Route path="/organisation/:orgId/xero/:integrationId/cash-journals" element={<Layout><XeroCashJournalsPage /></Layout>} />
              <Route path="/organisation/:orgId/xero/:integrationId/accrual-journals" element={<Layout><XeroAccrualJournalsPage /></Layout>} />
              <Route path="/organisation/:orgId/xero/:integrationId/journals/:type/:journalId" element={<Layout><XeroJournalDetailsPage /></Layout>} />
              <Route path="/organisation/:orgId/xero-integration/:xeroIntegrationId/fetch" element={<XeroFetchStatus />} />
              <Route path="/organisation/:orgId/xero-integration/:xeroIntegrationId/status" element={<XeroFetchStatus />} />

              <Route path="/organisation/:orgId/start-subscription" element={<Layout><EmbeddedCheckoutPage /></Layout>} />
            </Routes>
          </div>
        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;
