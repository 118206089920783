import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

function MainSidebar() {
  const location = useLocation();

  // Determine the current page
  const isActive = (path) => location.pathname === path;

  return (
    <div className="sidebar">
      <NavLink
        to="/home"
        className={`sidebar-button ${isActive('//home') ? 'active' : ''}`}
      >
        Home
      </NavLink>
    </div>
  );
}

export default MainSidebar;
