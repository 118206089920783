import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../authcontext';
import api from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function XeroIndirectCashFlowPage() {
  const { id, orgId } = useParams();
  const navigate = useNavigate();
  const { organisations, xeroIntegrations, isLoading, isLoggedIn } = useAuth();

  const [organisation, setOrganisation] = useState(null);
  const [integration, setIntegration] = useState(null);
  const [error, setError] = useState(null);
  const [allAccounts, setAllAccounts] = useState([]);
  const [cfRows, setCfRows] = useState([]);
  const [formulas, setFormulas] = useState({});

  // For collapsible groups:
  const [collapsedGroups, setCollapsedGroups] = useState({});

  // 24 months of periods
  const monthlyPeriods = useMemo(() => {
    return calculateMonthlyPeriods(new Date(), 24);
  }, []);

  function calculateMonthlyPeriods(currentDate, histPeriods = 24) {
    const periods = [];
    let date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    for (let i = 0; i < histPeriods; i++) {
      const label = date.toLocaleString('default', { month: '2-digit', year: 'numeric' });
      periods.unshift({ label }); // Only need label in this example
      date.setMonth(date.getMonth() - 1);
    }
    return periods;
  }

  // Toggle collapse for a subheading
  const toggleCollapse = (rowId) => {
    setCollapsedGroups((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  };

  // Fetch org + integration
  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      let foundOrg;
      if (orgId) {
        const orgIdInt = parseInt(orgId, 10);
        foundOrg = organisations.find((org) => org.id === orgIdInt);
      } else {
        foundOrg = organisations.find((org) =>
          xeroIntegrations[org.id]?.some((xi) => String(xi.id) === String(id))
        );
      }
      if (foundOrg) {
        setOrganisation(foundOrg);
        setError(null);
        const foundIntegration = xeroIntegrations[foundOrg.id]?.find(
          (xi) => String(xi.id) === String(id) && xi.connectionStatus === 'active'
        );
        if (foundIntegration) {
          setIntegration(foundIntegration);
        } else {
          setError('Xero Integration not found or inactive.');
        }
      } else {
        setError('Organisation not found in your data.');
      }
    }
  }, [id, orgId, organisations, xeroIntegrations, isLoading, isLoggedIn]);

  // Parse layout + accounts
  useEffect(() => {
    if (!integration) {
      setAllAccounts([]);
      setCfRows([]);
      setFormulas({});
      return;
    }
    // Parse accounts
    let parsedAccounts = [];
    try {
      const obj = JSON.parse(integration.accounts || '{}');
      if (Array.isArray(obj.accounts)) {
        parsedAccounts = obj.accounts;
      }
    } catch (err) {
      console.error('Error parsing accounts JSON:', err);
    }
    // Parse layout
    let cfIndirectLayoutData = [];
    try {
      cfIndirectLayoutData = JSON.parse(integration.cfIndirectLayout || '[]');
    } catch (err) {
      console.error('Error parsing cfIndirectLayout:', err);
      cfIndirectLayoutData = [];
    }
    // Parse formulas
    let parsedFormulas = {};
    try {
      parsedFormulas = JSON.parse(integration.formulas || '{}');
    } catch (err) {
      console.error('Error parsing formulas:', err);
      parsedFormulas = {};
    }

    setAllAccounts(parsedAccounts);
    setCfRows(cfIndirectLayoutData);
    setFormulas(parsedFormulas);
  }, [integration]);

  // Disconnect
  async function handleDisconnectXero() {
    try {
      const response = await api.post('/xero/disconnect', {
        organisation_id: organisation.id,
        xero_integration_id: integration.id,
      });
      if (response.data.message) {
        navigate(`/organisation/${organisation.id}`);
        window.location.reload();
      }
    } catch (err) {
      console.error('Error disconnecting from Xero:', err);
      alert('Failed to disconnect from Xero');
    }
  }

  // Value formatting
  function formatValue(val) {
    const v = Number(val);
    if (Math.abs(v) < 0.005) return '0.00';
    return v.toFixed(2);
  }

  /**
   * Recursively compute totals for a group of rows, ignoring "total" rows.
   * If it's the Operating Activities section (useSign=true),
   * then each row's "sign" field decides whether we flip the sign of the monthly values.
   * If it's the "Change in Cash" section, we do not invert.
   */
  function computeGroupTotals(rows, inChangeSection = false, useSign = false) {
    const totals = {};
    monthlyPeriods.forEach((p) => {
      totals[p.label] = 0;
    });
  
    rows.forEach((row) => {
      if (row.rowType === 'account') {
        // Find account by accountCode.
        const acct = allAccounts.find((a) => a.accountCode === row.accountCode);
        if (acct) {
          // When useSign is true (Operating Activities), use nonCashMonthlyValues.
          const monthlyVals = useSign ? acct.nonCashMonthlyValues : acct.cashMonthlyValues;
          if (monthlyVals) {
            monthlyPeriods.forEach((p) => {
              let val = Number(monthlyVals[p.label] || 0);
              if (useSign) {
                if (row.sign === '-') {
                  val = -val;
                }
              } else {
                if (!inChangeSection) {
                  val = -val;
                }
              }
              totals[p.label] += val;
            });
          }
        }
      } else if (row.rowType === 'subheading' || row.rowType === 'subheading2') {
        let nextInChange = inChangeSection;
        let nextUseSign = useSign;
        if (row.label.trim().toLowerCase() === 'change in cash') {
          nextInChange = true;
        }
        if (row.label.trim().toLowerCase() === 'operating activities') {
          nextUseSign = true;
        }
        // Compute child totals (ignoring "total" rows)
        const childRows = (row.nestedRows || []).filter((r) => r.rowType !== 'total');
        const childTotals = computeGroupTotals(childRows, nextInChange, nextUseSign);
        monthlyPeriods.forEach((p) => {
          totals[p.label] += childTotals[p.label];
        });
      }
    });
  
    return totals;
  }

  // Optionally, if you want to compute or display them outside the table:
  function computeTotalsForGroupLabel(label) {
    const group = cfRows.find(
      (r) =>
        r.rowType === 'subheading' &&
        r.label.trim().toLowerCase() === label.trim().toLowerCase()
    );
    if (!group || !group.nestedRows) {
      const zeros = {};
      monthlyPeriods.forEach((p) => {
        zeros[p.label] = 0;
      });
      return zeros;
    }
    // We only "useSign" if it's Operating Activities
    const useSign = label.trim().toLowerCase() === 'operating activities';
    const childRows = group.nestedRows.filter((r) => r.rowType !== 'total');
    // For operating activities, add the net profit values in the totals.
    const childTotals = computeGroupTotals(childRows, false, useSign);
    if (useSign) {
      const np = formulas['Net Profit'] || {};
      monthlyPeriods.forEach((p) => {
        childTotals[p.label] += Number(np[p.label] || 0);
      });
    }
    return childTotals;
  }

  // If you want Net Cash Flow:
  function computeNetTotals() {
    // compute totals for Operating, Investing, Financing Activities
    const op = computeTotalsForGroupLabel('Operating Activities');
    const inv = computeTotalsForGroupLabel('Investing Activities');
    const fin = computeTotalsForGroupLabel('Financing Activities');

    // Get the net profit values from formulas. (For net cash flow we already added net profit to operating totals above.
    const net = {};
    monthlyPeriods.forEach((p) => {
      net[p.label] = (op[p.label] || 0) + (inv[p.label] || 0) + (fin[p.label] || 0);
    });
    console.log('Net Cash Flow:', net);
    return net;
  }

  // Render rows recursively
  // We add an extra parameter (parentUseSign) to signal if we’re inside Operating Activities.

function renderRowsRecursively(
    rows,
    indent = 0,
    inChangeSection = false,
    useSign = false,
    groupRows = rows.filter((r) => r.rowType !== 'total')
  ) {
    return rows.flatMap((row) => {
      const indentPx = indent * 20;
      let result = [];
  
      if (row.rowType === 'subheading' || row.rowType === 'subheading2') {
        let nextInChange = inChangeSection;
        let nextUseSign = useSign;
        if (row.label.trim().toLowerCase() === 'change in cash') {
          nextInChange = true;
        }
        if (row.label.trim().toLowerCase() === 'operating activities') {
          nextUseSign = true;
        }
  
        // compute the subheading's own totals from its children (ignoring "total" rows)
        const subset = (row.nestedRows || []).filter((r) => r.rowType !== 'total');
        const rowTotals = computeGroupTotals(subset, nextInChange, nextUseSign);
  
        // If this is Operating Activities, add net profit to its totals.
        if (row.label.trim().toLowerCase() === 'operating activities') {
          const np = formulas['Net Profit'] || {};
          monthlyPeriods.forEach((p) => {
            rowTotals[p.label] += Number(np[p.label] || 0);
          });
        }
  
        result.push(
          <tr
            key={row.id}
            style={{
              fontWeight: 'bold',
              backgroundColor: '#f7f7f7',
              borderBottom: '1px solid #e0e0e0'
            }}
          >
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 2,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                display: 'flex',
                alignItems: 'center',
                minWidth: '280px'
              }}
            >
              <span
                style={{ cursor: 'pointer', marginRight: '8px' }}
                onClick={() => toggleCollapse(row.id)}
              >
                <FontAwesomeIcon
                  icon={collapsedGroups[row.id] ? faChevronRight : faChevronDown}
                  size="sm"
                  color="#555"
                />
              </span>
              <span style={{ marginLeft: indentPx }}>{row.label}</span>
            </td>
            {monthlyPeriods.map((p, i) => {
              const val = rowTotals[p.label] || 0;
              return (
                <td
                  key={i}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right',
                    backgroundColor: '#fafafa'
                  }}
                >
                  {formatValue(val)}
                </td>
              );
            })}
          </tr>
        );
  
        // If this is the Operating Activities subheading and it's not collapsed,
        // insert our custom Net Profit row (with additional indent) as a child row.
        if (
          row.label.trim().toLowerCase() === 'operating activities' &&
          !collapsedGroups[row.id]
        ) {
          const np = formulas['Net Profit'] || {};
          result.push(
            <tr
              key={`${row.id}-netprofit`}
              style={{
                backgroundColor: '#e6f7ff' // not bold; same as before
              }}
            >
              <td
                style={{
                  position: 'sticky',
                  left: '-2px',
                  zIndex: 2,
                  backgroundColor: '#fff',
                  border: '1px solid #e0e0e0',
                  padding: '8px 10px',
                  minWidth: '280px'
                }}
              >
                {/* Increase the left indent so the text appears with further right indentation */}
                <span style={{ marginLeft: indentPx + 20 }}>Net Profit</span>
              </td>
              {monthlyPeriods.map((p, i) => (
                <td
                  key={i}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right'
                  }}
                >
                  {formatValue(Number(np[p.label] || 0))}
                </td>
              ))}
            </tr>
          );
        }
  
        // If not collapsed, render children rows
        if (!collapsedGroups[row.id] && row.nestedRows?.length) {
          const sorted = [...row.nestedRows].sort((a, b) => {
            if (a.rowType === 'total' && b.rowType !== 'total') return 1;
            if (a.rowType !== 'total' && b.rowType === 'total') return -1;
            return 0;
          });
          result = result.concat(
            renderRowsRecursively(sorted, indent + 1, nextInChange, nextUseSign)
          );
        }
      }
      // ... (the rest of renderRowsRecursively remains unchanged)
      else if (row.rowType === 'account') {
        // Find account by accountCode.
        const acct = allAccounts.find((a) => a.accountCode === row.accountCode) || {};
        // When inside Operating Activities (useSign true) use nonCashMonthlyValues:
        const monthlyVals = useSign ? acct.nonCashMonthlyValues : acct.cashMonthlyValues;
        result.push(
          <tr key={row.id}>
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 1,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                minWidth: '280px'
              }}
            >
              <span style={{ marginLeft: indentPx }}>{row.name}</span>
            </td>
            {monthlyPeriods.map((p, i) => {
              let val = Number(monthlyVals?.[p.label] || 0);
              if (useSign) {
                if (row.sign === '-') val = -val;
              } else {
                if (!inChangeSection) val = -val;
              }
              return (
                <td
                  key={i}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right'
                  }}
                >
                  {formatValue(val)}
                </td>
              );
            })}
          </tr>
        );
      }
      
      // ... (continue for row types "total", "formula", etc.)
      else if (row.rowType === 'total') {
        const totals = computeGroupTotals(groupRows, inChangeSection, useSign);
        if (useSign) {
          const np = formulas['Net Profit'] || {};
          monthlyPeriods.forEach((p) => {
            totals[p.label] += Number(np[p.label] || 0);
          });
        }
        result.push(
          <tr
            key={row.id}
            style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}
          >
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 2,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                minWidth: '280px'
              }}
            >
              <span style={{ marginLeft: indentPx }}>{row.label}</span>
            </td>
            {monthlyPeriods.map((p, i) => {
              let val = totals[p.label] || 0;
              return (
                <td
                  key={i}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right'
                  }}
                >
                  {formatValue(val)}
                </td>
              );
            })}
          </tr>
        );
      } else if (row.rowType === 'formula') {
        if (row.label.trim().toLowerCase() === 'net profit') {
          return [];
        }
        if (row.label.trim().toLowerCase() === 'net cash flow') {
            return renderNetCashFlowRow();
          }
        let computed = {};
        if (row.label.trim().toLowerCase() === 'net profit') {
          computed = formulas['Net Profit'] || {};
        }
        result.push(
          <tr
            key={row.id}
            style={{ fontWeight: 'bold', backgroundColor: '#e6f7ff' }}
          >
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 2,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                minWidth: '280px'
              }}
            >
              <span style={{ marginLeft: indentPx }}>{row.label}</span>
            </td>
            {monthlyPeriods.map((p, i) => {
              let val = 0;
              if (computed[p.label] !== undefined) {
                val = Number(computed[p.label]);
              }
              return (
                <td
                  key={i}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right'
                  }}
                >
                  {formatValue(val)}
                </td>
              );
            })}
          </tr>
        );
      } else if (row.rowType === 'text') {
        result.push(
          <tr key={row.id} style={{ fontStyle: 'italic' }}>
            <td
              style={{
                position: 'sticky',
                left: '-2px',
                zIndex: 2,
                backgroundColor: '#fff',
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                minWidth: '280px'
              }}
            >
              <span style={{ marginLeft: indentPx }}>{row.label}</span>
            </td>
            {monthlyPeriods.map((_, i) => (
              <td
                key={i}
                style={{
                  border: '1px solid #e0e0e0',
                  padding: '8px 10px',
                  textAlign: 'right'
                }}
              />
            ))}
          </tr>
        );
      }
      return result;
    });
  }

  // If loading or errors
  if (isLoading) return <p>Loading global data...</p>;
  if (error) return <p>{error}</p>;
  if (!organisation) return <p>Organisation not found.</p>;
  if (!integration) return <p>Xero Integration not found or inactive.</p>;

  // Build net-cash-flow row for demonstration
  const netFlow = computeNetTotals();

  function renderNetCashFlowRow() {
    return (
      <tr style={{ fontWeight: 'bold', backgroundColor: '#e6f7ff' }}>
        <td
          style={{
            position: 'sticky',
            left: '-2px',
            zIndex: 2,
            backgroundColor: '#fff',
            border: '1px solid #e0e0e0',
            padding: '8px 10px',
            minWidth: '280px'
          }}
        >
          Net Cash Flow
        </td>
        {monthlyPeriods.map((p, i) => {
          const val = netFlow[p.label] || 0;
          return (
            <td
              key={i}
              style={{
                border: '1px solid #e0e0e0',
                padding: '8px 10px',
                textAlign: 'right'
              }}
            >
              {formatValue(val)}
            </td>
          );
        })}
      </tr>
    );
  }

  const subtleBlueButtonStyle = {
    marginRight: '10px',
    padding: '8px 12px',
    border: 'none',
    backgroundColor: '#dbdbdb',
    color: '#000',
    cursor: 'pointer',
    borderRadius: '4px'
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', color: '#333' }}>
      <h2 style={{ marginBottom: '10px' }}>{organisation.name}</h2>
      <div style={{ marginBottom: '10px' }}>
        <Link
          to={`/organisation/${organisation.id}/activity-log`}
          style={{ marginRight: '15px', color: '#007bff', textDecoration: 'none' }}
        >
          Activity Log
        </Link>
        <Link
          to={`/organisation/${organisation.id}/start-subscription`}
          style={{ color: '#007bff', textDecoration: 'none' }}
        >
          Start Subscription
        </Link>
      </div>
      <p style={{ margin: '5px 0' }}>Created By: {organisation.createdBy}</p>
      <p style={{ margin: '5px 0' }}>Stripe ID: {organisation.stripeId}</p>
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <button style={subtleBlueButtonStyle} onClick={() => navigate(`/organisation/${organisation.id}`)}>
          Back to Organisation
        </button>
        <button
          style={subtleBlueButtonStyle}
          onClick={() =>
            navigate(`/organisation/${organisation.id}/xero/${integration.id}/edit-cf-indirect-layout`)
          }
        >
          Edit Indirect Cash Flow Layout
        </button>
        <button style={{ ...subtleBlueButtonStyle, marginLeft: '10px' }} onClick={handleDisconnectXero}>
          Disconnect Xero
        </button>
      </div>

      <div style={{ maxHeight: '600px', overflowY: 'auto', overflowX: 'auto' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ borderBottom: '2px solid #ccc', backgroundColor: '#f0f0f0' }}>
              <th
                style={{
                  border: '1px solid #e0e0e0',
                  padding: '8px 10px',
                  textAlign: 'left',
                  position: 'sticky',
                  top: 0,
                  left: '-2px',
                  backgroundColor: '#f0f0f0',
                  zIndex: 3,
                  minWidth: '300px'
                }}
              >
                Name
              </th>
              {monthlyPeriods.map((p) => (
                <th
                  key={p.label}
                  style={{
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    textAlign: 'right',
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#f0f0f0'
                  }}
                >
                  {p.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderRowsRecursively(cfRows)}
            {/* Optionally, display Net Cash Flow row: */}
          </tbody>
        </table>
      </div>

      <div style={{ marginTop: '20px' }}>
        <h3 style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>QuickBooks</h3>
        <p>To be implemented...</p>
      </div>
    </div>
  );
}
