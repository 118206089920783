import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../authcontext';
import api from '../api';

const ConsolidatedAccountMatchesPage = () => {
  const { id, orgId } = useParams();
  const navigate = useNavigate();
  const { organisations, consolidations, isLoading, isLoggedIn } = useAuth();
  
  const [organisation, setOrganisation] = useState(null);
  const [consolidation, setConsolidation] = useState(null);
  const [accountMatches, setAccountMatches] = useState({ header: { companies: [] }, matches: [] });
  const [error, setError] = useState(null);
  
  // 1) Load organisation and consolidation (similar to other pages)
  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      const orgIdInt = parseInt(orgId, 10);
      const foundOrg = organisations.find(o => o.id === orgIdInt);
      if (!foundOrg) {
        setError("Organisation not found.");
        return;
      }
      setOrganisation(foundOrg);
      
      const foundCons = consolidations[foundOrg.id]?.find(c => String(c.id) === String(id));
      if (!foundCons) {
        setError("Consolidation not found or inactive.");
        return;
      }
      setConsolidation(foundCons);
      setError(null);
    }
  }, [id, orgId, organisations, consolidations, isLoading, isLoggedIn]);
  
  // 2) Parse the "accounts" field (which holds the consolidated account matches)
  useEffect(() => {
    if (!consolidation) return;
    try {
      if (consolidation.accounts) {
        const parsed = JSON.parse(consolidation.accounts);
        setAccountMatches(parsed);
      }
    } catch (e) {
      console.error("Error parsing consolidation accounts:", e);
      setError("Failed to parse account matches.");
    }
  }, [consolidation]);
  
  // Helper: Given a company id (e.g. "xero_1" or "quickbooks_14"), return the integration type with first letter capitalized.
  const getIntegrationType = (companyId) => {
    if (!companyId) return "";
    const parts = companyId.split('_');
    const type = parts[0];
    return type.charAt(0).toUpperCase() + type.slice(1);
  };
  
  if (isLoading) return <p>Loading global data...</p>;
  if (error) return <p>{error}</p>;
  if (!organisation) return <p>Organisation not found.</p>;
  if (!consolidation) return <p>Consolidation not found or inactive.</p>;
  
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', color: '#333' }}>
      <h2>{organisation.name} - Account Matches</h2>
      <div style={{ marginBottom: '20px' }}>
        <button onClick={() => navigate(`/organisation/${organisation.id}`)} style={{ marginRight: '10px' }}>
          Back to Organisation
        </button>
        <button
          onClick={() => navigate(`/organisation/${organisation.id}/consolidation/${consolidation.id}`)}
          style={{ marginRight: '10px' }}
        >
          Back to Consolidation
        </button>
        {/* You might add additional buttons here for editing, etc. */}
      </div>
      <div style={{ overflowX: 'auto' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ backgroundColor: '#f0f0f0', borderBottom: '2px solid #ccc' }}>
              {/* Single fixed "Name" column */}
              <th
                style={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 3,
                  backgroundColor: '#f0f0f0',
                  border: '1px solid #e0e0e0',
                  padding: '8px 10px',
                  minWidth: '200px',
                  textAlign: 'left'
                }}
              >
                Name
              </th>
              {/* One column per company */}
              {accountMatches.header.companies.map((comp) => (
                <th
                  key={comp.id}
                  style={{
                    border: '1px solid #e0e0e0',
                    textAlign: 'right',
                    padding: '8px 10px',
                    backgroundColor: '#f0f0f0'
                  }}
                >
                  {comp.name} - {getIntegrationType(comp.id)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {accountMatches.matches.map(match => (
              <tr key={match.id}>
                <td
                  style={{
                    position: 'sticky',
                    left: 0,
                    zIndex: 2,
                    backgroundColor: '#fff',
                    border: '1px solid #e0e0e0',
                    padding: '8px 10px',
                    minWidth: '200px',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {match.displayName || match.accountName}
                </td>
                {accountMatches.header.companies.map(comp => (
                  <td
                    key={`${match.id}_${comp.id}`}
                    style={{
                      border: '1px solid #e0e0e0',
                      textAlign: 'right',
                      padding: '8px 10px'
                    }}
                  >
                    {match.accounts[comp.id] ? match.accounts[comp.id].name : ''}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Link
          to={`/organisation/${organisation.id}/consolidation/${consolidation.id}`}
          style={{ color: '#007bff', textDecoration: 'none' }}
        >
          Back to Consolidation
        </Link>
      </div>
    </div>
  );
};

export default ConsolidatedAccountMatchesPage;
