// src/Components/organisationpage.js

import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuth } from './authcontext';
import api from './api';

const OrganisationPage = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { organisations, xeroIntegrations, consolidations, isLoading, isLoggedIn } = useAuth();

  // Local state for organisation and error
  const [organisation, setOrganisation] = useState(null);
  const [error, setError] = useState(null);

  // New state for toggling creation form and form fields for consolidation
  const [showConsolidationForm, setShowConsolidationForm] = useState(false);
  const [newConsolidationName, setNewConsolidationName] = useState('');
  const [selectedIntegrations, setSelectedIntegrations] = useState({}); // key: integration id, value: boolean

  // Calculate monthly periods using a memoized function (existing functionality)
  const monthlyPeriods = useMemo(() => calculateMonthlyPeriods(new Date(), 24), []);

  function calculateMonthlyPeriods(currentDate, histPeriods = 24) {
    const periods = [];
    let date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    for (let i = 0; i < histPeriods; i++) {
      const label = date.toLocaleString('default', { month: '2-digit', year: 'numeric' });
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];
      periods.unshift({ label, startDate, endDate });
      date.setMonth(date.getMonth() - 1);
    }
    return periods;
  }

  // Fetch organisation (existing)
  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      const orgIdInt = parseInt(orgId, 10);
      const found = organisations.find(o => o.id === orgIdInt);
      if (found) {
        setOrganisation(found);
        setError(null);
      } else {
        setError('Organisation not found in your data.');
      }
      console.log('consol', consolidations, 'xero', xeroIntegrations, orgConsolidations, orgXeroIntegrations)
    }
  }, [orgId, organisations, isLoading, isLoggedIn]);

  // Grab active Xero integrations (existing)
  const orgXeroIntegrations = useMemo(() => {
    if (!organisation || !xeroIntegrations[organisation.id]) return [];
    return xeroIntegrations[organisation.id].filter(xi => xi.connectionStatus === 'active');
  }, [organisation, xeroIntegrations]);

  // Grab consolidations (existing)
  const orgConsolidations = useMemo(() => {
    if (!organisation || !consolidations[organisation.id]) return [];
    return consolidations[organisation.id];
  }, [organisation, consolidations]);

  // Handler for disconnecting Xero integration (existing)
  const handleDisconnectXero = async (integrationId) => {
    try {
      const response = await api.post('/xero/disconnect', {
        organisation_id: organisation.id,
        xero_integration_id: integrationId,
      });
      if (response.data.message) {
        window.location.reload();
      }
    } catch (err) {
      console.error('Error disconnecting from Xero:', err);
      alert('Failed to disconnect from Xero');
    }
  };

  // Toggle checkbox selection for integrations in the consolidation form
  const handleIntegrationCheckboxChange = (integrationId) => {
    setSelectedIntegrations(prev => ({
      ...prev,
      [integrationId]: !prev[integrationId],
    }));
  };

  // Handler for creating consolidation (calls the new endpoint)
  const handleCreateConsolidation = async (e) => {
    e.preventDefault();
    // Build list of selected integrations
    const integratedEntities = orgXeroIntegrations
      .filter(xi => selectedIntegrations[xi.id])
      .map(xi => ({ integrationType: "Xero", integrationId: xi.id }));

    // Prepare data for POST; layout fields are blank for now.
    const consolidationData = {
      organisationId: organisation.id,
      name: newConsolidationName,
      integratedEntities: JSON.stringify(integratedEntities),
      plLayout: '',
      bsLayout: '',
      cfDirectLayout: '',
      cfIndirectLayout: '',
    };

    try {
      await api.post('/consolidations', consolidationData);
      // Reset the form
      setShowConsolidationForm(false);
      setNewConsolidationName('');
      setSelectedIntegrations({});
      window.location.reload()
    } catch (err) {
      console.error('Error creating consolidation:', err);
      alert('Failed to create consolidation.');
    }
  };

    // Handler for deleting a consolidation
    const handleDeleteConsolidation = async (consolidation_id) => {
      if (!window.confirm("Are you sure you want to delete this consolidation?")) {
        return;
      }
      try {
        const response = await api.post('/consolidations/delete', {
          organisation_id: organisation.id,
          consolidation_id: consolidation_id
        });
        if (response.data.message) {
          window.location.reload();
        }
      } catch (err) {
        console.error('Error deleting consolidation:', err);
        alert('Failed to delete consolidation.');
      }
    };

  // If loading or error, handle accordingly
  if (isLoading) return <p>Loading global data...</p>;
  if (error) return <p>{error}</p>;
  if (!organisation) return <p>Organisation not found.</p>;

  return (
    <div>
      <h2>{organisation.name}</h2>
      <Link to={`/organisation/${organisation.id}/activity-log`}>Activity Log</Link>
      <Link to={`/organisation/${organisation.id}/start-subscription`}>Start Subscription</Link>
      <p>Created By: {organisation.createdBy}</p>
      <p>Stripe ID: {organisation.stripeId}</p>

      {/* Xero Integrations Section */}
      <div style={{ marginTop: '20px' }}>
        <h3>Xero Integrations</h3>
        {orgXeroIntegrations.length === 0 ? (
          <div>
            <p>No Xero integration found for this organisation.</p>
            <button
              onClick={() => {
                window.location.href = `https://b538-81-101-103-35.ngrok-free.app/api/xero/connect?organisation_id=${orgId}`;
              }}
            >
              Connect to Xero
            </button>
          </div>
        ) : (
          <div className="scrollable-table-container">
            <table className="data-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Year End</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orgXeroIntegrations.map(xi => (
                  <tr key={xi.id}>
                    <td>{xi.name}</td>
                    <td>{xi.yearEnd}</td>
                    <td>
                      <Link to={`/organisation/${orgId}/xeropandl/${xi.id}`}>
                        <button>Profit and Loss</button>
                      </Link>
                      <Link to={`/organisation/${orgId}/xerobs/${xi.id}`} style={{ marginLeft: '10px' }}>
                        <button>Balance Sheet</button>
                      </Link>
                      <Link to={`/organisation/${orgId}/xerocfdirect/${xi.id}`} style={{ marginLeft: '10px' }}>
                        <button>Direct Cash Flow</button>
                      </Link>
                      <Link to={`/organisation/${orgId}/xerocfindirect/${xi.id}`} style={{ marginLeft: '10px' }}>
                        <button>Indirect Cash Flow</button>
                      </Link>
                      <Link to={`/organisation/${orgId}/xero/${xi.id}/cash-journals`} style={{ marginLeft: '10px' }}>
                        <button>Cash Journals</button>
                      </Link>
                      <Link to={`/organisation/${orgId}/xero/${xi.id}/accrual-journals`} style={{ marginLeft: '10px' }}>
                        <button>Accrual Journals</button>
                      </Link>
                      <button onClick={() => handleDisconnectXero(xi.id)} style={{ marginLeft: '10px' }}>
                        Disconnect
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={3} style={{ textAlign: 'center' }}>
                    <button
                      onClick={() => {
                        window.location.href = `https://b538-81-101-103-35.ngrok-free.app/api/xero/connect?organisation_id=${orgId}`;
                      }}
                    >
                      Add Another Xero Integration
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Consolidations Section */}
      <div style={{ marginTop: '40px' }}>
        <h3>Consolidations</h3>
        {orgConsolidations.length === 0 ? (
          <p>No consolidations created yet.</p>
        ) : (
          <div className="scrollable-table-container">
            <table className="data-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Integrated Entities</th>
                  <th>Created Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orgConsolidations.map(cons => (
                  <tr key={cons.id}>
                    <td>{cons.name}</td>
                    <td>
                      {cons.integratedEntities
                        ? JSON.parse(cons.integratedEntities)
                            .map(ie => `${ie.integrationType} (${ie.integrationId})`)
                            .join(', ')
                        : ''}
                    </td>
                    <td>{cons.createdDate}</td>
                    <td>
                      <Link to={`/organisation/${orgId}/consolidatedpandl/${cons.id}`}>
                        <button>Profit and Loss</button>
                      </Link>
                      <Link to={`/organisation/${orgId}/consolidatedaccounts/${cons.id}`}>
                        <button>Edit Account Groupings</button>
                      </Link>
                      <button 
                        onClick={() => handleDeleteConsolidation(cons.id)}
                        style={{ marginRight: '10px' }}
                      >
                        Delete
                      </button>
                      {/* Additional actions (such as "Edit") can be added here */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Create Consolidation Form */}
        <div style={{ marginTop: '20px' }}>
          {!showConsolidationForm ? (
            <button onClick={() => setShowConsolidationForm(true)}>Create Consolidation</button>
          ) : (
            <div style={{ marginTop: '10px', padding: '10px', border: '1px solid #ccc' }}>
              <h4>Create Consolidation</h4>
              <form onSubmit={handleCreateConsolidation}>
                <div style={{ marginBottom: '10px' }}>
                  <label>
                    Consolidation Name:{' '}
                    <input
                      type="text"
                      value={newConsolidationName}
                      onChange={(e) => setNewConsolidationName(e.target.value)}
                      required
                    />
                  </label>
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <p>Select integrations to consolidate:</p>
                  {orgXeroIntegrations.map(xi => (
                    <div key={xi.id}>
                      <label>
                        <input
                          type="checkbox"
                          checked={!!selectedIntegrations[xi.id]}
                          onChange={() => handleIntegrationCheckboxChange(xi.id)}
                        />{' '}
                        {`Xero - ${xi.name}`}
                      </label>
                    </div>
                  ))}
                </div>
                <div>
                  <button type="submit">Save Consolidation</button>{' '}
                  <button type="button" onClick={() => setShowConsolidationForm(false)}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>

      {/* QuickBooks placeholder */}
      <div style={{ marginTop: '20px' }}>
        <h3>QuickBooks</h3>
        <p>To be implemented...</p>
      </div>
    </div>
  );
};

export default OrganisationPage;
