import React, { createContext, useContext, useState, useEffect } from 'react';
import api from './api';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [organisations, setOrganisations] = useState([]);
    const [currentOrg, setCurrentOrg] = useState(null);
    const [xeroIntegrations, setXeroIntegrations] = useState({});
    const [activityLogs, setActivityLogs] = useState([]);
    const [user, setUser] = useState({});
    const [consolidations, setConsolidations] = useState([]);

    // Load currentOrg, currentModel, expandedFolders, and expandedSuperModels from localStorage on mount
    useEffect(() => {
        const storedCurrentOrg = localStorage.getItem('currentOrg');
        if (storedCurrentOrg) {
            const parsedOrg = JSON.parse(storedCurrentOrg);
            setCurrentOrg(parsedOrg);
        }
    }, []);

    // Save currentOrg, currentModel, expandedFolders, and expandedSuperModels to localStorage whenever they change
    useEffect(() => {
        if (currentOrg) {
            localStorage.setItem('currentOrg', JSON.stringify(currentOrg));
        } else {
            localStorage.removeItem('currentOrg');
        }
    }, [currentOrg]);

    // Consolidated Data Fetching
    useEffect(() => {
        const fetchAndInitialize = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setIsLoggedIn(false);
                setIsLoading(false);
                return;
            }
            setIsLoading(true);
            try {
                const response = await api.get('/fetchuserdata', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                
                const fetchedUser = response.data.user;
                setUser(fetchedUser); // Store all user fields
                setXeroIntegrations(response.data.xero_integrations || {});
                setOrganisations(response.data.organisations);
                setActivityLogs(response.data.activity_logs);
                setConsolidations(response.data.consolidations || []);

                setIsLoggedIn(true);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setIsLoggedIn(false);
                logout();
            } finally {
                setIsLoading(false);
            }
        };

        fetchAndInitialize();
    }, []);

    const login = async (token, refreshToken) => {
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        setIsLoading(true);

        try {
            const response = await api.get('/fetchuserdata', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const fetchedUser = response.data.user;
            setUser(fetchedUser); // Store all user fields
            setOrganisations(response.data.organisations);
            setActivityLogs(response.data.activity_logs);
            setIsLoggedIn(true);
        } catch (error) {
            console.error('Error fetching user data on login:', error);
            setIsLoggedIn(false);
        } finally {
            setIsLoading(false);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('currentOrg');
        setIsLoggedIn(false);
        setOrganisations([]);
        setCurrentOrg(null);
        setActivityLogs([]);
        setUser({});
    };

    return (
        <AuthContext.Provider value={{
            isLoggedIn,
            isLoading,
            organisations,
            setOrganisations,
            xeroIntegrations,
            setXeroIntegrations,
            consolidations, 
            setConsolidations,
            currentOrg,
            setCurrentOrg,
            activityLogs,
            login,
            logout,
            user,
            setUser, 
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
